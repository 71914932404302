import { API_INSTANCE } from '../../../axios';

/**
 * Function for login user and set the local storage
 * @param dispatch dispatcher to send data to AuthReducer
 * @param loginPayload data to save in reducer
 * @returns {boolean}
 */
export async function loginUser(dispatch, loginPayload) {
    try {
        dispatch({ type: 'REQUEST_LOGIN' });
        let response = await API_INSTANCE.post('/login', loginPayload);

        if (response.status === 200) {
            let JWT = response.data.auth;
            let time = new Date().toUTCString();
            localStorage.setItem("JWT", JWT)
            localStorage.setItem("login_at", time)

            delete response.data.password;
            delete response.data.auth;
            
            localStorage.setItem("user", JSON.stringify(response.data))

            dispatch({ type: 'LOGIN_SUCCESS', payload: { data: response.data, JWT } });
            return true;
        }
    } catch (error) {
        dispatch({ type: 'LOGIN_ERROR', error: error.response });
        return false
    }
}

/**
 * Dispatch logout and reset local storage
 * @param dispatch dispatcher to send data to AuthReducer 
 */
export async function logout(dispatch) {
    localStorage.removeItem("JWT");
    localStorage.removeItem('user');
    localStorage.removeItem('login_at');
    dispatch({ type: 'LOGOUT' });
}