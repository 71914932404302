import React from "react";
import { Button, Result, Spin, Typography } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { API_REQUEST, GET, GET_REQUEST, POST } from "./Shared/Requests";
import { Loader } from "./Shared/Layout";
import { useParams } from "react-router-dom";
import Login from "./Login";
import { CloseCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
// @ts-ignore
import logoUrl from "../Resources/Logo.png";

const { Paragraph, Text } = Typography;

const ResetPassword = () => {
  const { user_id, password }: any = useParams();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(true);
  const history = useHistory();

  useEffect(() => {
    confirmPassword();
  }, []);

  const confirmPassword = async () => {
    try {
      setLoading(true);
      let res = await fetch(
        `https://9sxoitdurl.execute-api.eu-west-1.amazonaws.com/default/reset_password/${user_id}/${password}`
      );

      if (res.status === 204) {
        setSuccess(true);
      } else {
        setSuccess(false);
      }

      setLoading(false);
    } catch (error) {}
  };

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img src={logoUrl} alt="logo" style={{ width: "40%" }} />
      </div>
      {loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "2em",
          }}
        >
          <Spin indicator={<LoadingOutlined style={{ fontSize: 72 }} spin />} />
          <Typography.Title level={3}>
            we're resetting your password!
          </Typography.Title>
        </div>
      ) : (
        <div>
          {!success ? (
            <Result
              status="error"
              title="Verify Mail Failed"
              subTitle="Please contact your Admin"
              extra={[
                <a href="/">
                  <Button type="primary" key="error">
                    Go to Login
                  </Button>
                </a>,
              ]}
            ></Result>
          ) : (
            <Result
              status="success"
              title="Mail Verified"
              //   subTitle="Please contact Alessio Ciferri."
              extra={[
                <a href="/">
                  <Button
                    type="primary"
                    key="success"
                    style={{
                      backgroundColor: "#52c41a",
                      borderColor: "#52c41a",
                    }}
                  >
                    Go to Login
                  </Button>
                </a>,
              ]}
            ></Result>
          )}
        </div>
      )}
    </div>
  );
};

export default ResetPassword;
