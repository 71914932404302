import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import Orders from "./Order";
import ItemTypes from "./ItemTypes";
import { customer, orders, itemTypes } from "./../Shared/Interface";
import { ErrorHandler } from "../Shared/ErrorHandler";
import { Layout } from "./../Shared/Layout";
import {
  CUSTOMERS_PATH,
  GET_REQUEST,
  ITEM_TYPES_PATH,
  ORDERS_PATH,
  USERS_PATH,
} from "../Shared/Requests";
import Clients from "./Clients";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";

/**
 * The main component of the admin component.
 * In here we have Customers, ItemTypes and Orders as child components
 * @returns {React.FC}
 */
const Admin = () => {
  const { key }: any = useParams();
  const [orders, setOrders] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [users, setUsers] = useState([]);
  const [itemTypes, setItemTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  /**
   * This useEffect do 3 get requests to get data for child components
   */
  useEffect(() => {
    try {
      getCustomers();
      getUsers();
      getOrders();
      getItemTypes();
    } catch (error) {
      ErrorHandler(error.response);
    }
  }, []);

  /**
   * In this async function get the customers and set the state
   */
  const getCustomers = async () => {
    setLoading(true);

    GET_REQUEST<customer[]>(CUSTOMERS_PATH)
      .then(setCustomers)
      .catch((err) => ErrorHandler(err.response))
      .finally(() => setLoading(false));
  };

  /**
   * In this async function get the customers and set the state
   */
  const getUsers = async () => {
    setLoading(true);

    GET_REQUEST<customer[]>(USERS_PATH)
      .then(setUsers)
      .catch((err) => ErrorHandler(err.response))
      .finally(() => setLoading(false));
  };

  /**
   * In this async function get the orders and set the state
   */
  const getOrders = async () => {
    setLoading(true);

    GET_REQUEST<orders[]>(ORDERS_PATH)
      .then(setOrders)
      .catch((err) => ErrorHandler(err.response))
      .finally(() => setLoading(false));
  };

  /**
   * In this async function get the item types and set the state
   */
  const getItemTypes = async () => {
    setLoading(true);

    GET_REQUEST<itemTypes[]>(ITEM_TYPES_PATH)
      .then(setItemTypes)
      .catch((err) => ErrorHandler(err.response))
      .finally(() => setLoading(false));
  };

  return (
    <Layout>
      <div id="admin">
        <Tabs
          key="order_index"
          className="profile-card-tabs"
          defaultActiveKey={key}
          type="line"
          onChange={(key) => history.push(`/admin/${key}`)}
        >
          <Tabs.TabPane tab="Clients" key="1">
            <Clients
              key="user"
              dataSource={customers}
              setDataSource={setCustomers}
              loading={loading}
              orders={orders}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Item types" key="2">
            <ItemTypes
              key="item_type"
              dataSource={itemTypes}
              setDataSource={setItemTypes}
              loading={loading}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Orders" key="3">
            <Orders
              key="order"
              dataSource={orders}
              customers={customers}
              users={users}
              setDataSource={setOrders}
              loading={loading}
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </Layout>
  );
};

export default Admin;
