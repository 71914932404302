import React, { useEffect, useState } from "react";
import { PageHeader, List, message } from "antd";
import { Layout } from "../Shared/Layout";
import { customer, orders } from "../Shared/Interface";
import {
  loginUser,
  useAuthDispatch,
  useAuthState,
} from "../Shared/AuthContext";
import { Card, Col, Empty, Row, Skeleton } from "antd";
import { GET_REQUEST, ORDERS_PATH } from "../Shared/Requests";
import { ErrorHandler } from "../Shared/ErrorHandler";
import { useHistory, useParams } from "react-router";
import useWindowDimensions from "../../Hooks/useWindowDimensions";
/**
 * Main component of customer interface,
 * starts with displaying Orders
 * @returns {React.FC}
 */
const Orders = () => {
  const { username, password, order_id, order_name }: any = useParams();
  const { user }: any = useAuthState();
  const dispatch = useAuthDispatch();
  const h = useHistory();

  useEffect(() => {
    let login = h.location.pathname.split("/")[2];

    login === "login" && !user
      ? userLogin()
      : order_id && order_name
      ? h.push(`/orders/${order_id}/${order_name}`)
      : h.push("/orders");
  }, [username, password, order_id, order_name]);

  const userLogin = async () => {
    try {
      let response = await loginUser(dispatch, {
        username,
        password,
      });

      if (!response) throw response;

      h.push(`/orders/${order_id}/${order_name}`);
      message.success("User logged in successful");
    } catch (error) {
      ErrorHandler(error.response);
    }
  };

  /**
   * change component between Order, Item and Media
   * @param focus to understand on which component selected
   */
  const changeFocus = (focus, title) => {
    h.push(`/orders/${focus}/${title}`);
  };

  return (
    <Layout>
      <div id="customer">
        <PageHeader
          title={"Orders"}
          subTitle={"Click an order to see the items"}
          onBack={null}
        />

        <List size="large">
          {user && <OrderCard changeFocus={changeFocus} user={user} />}
        </List>
      </div>
    </Layout>
  );
};

export default Orders;

type OrderCardProps = {
  changeFocus: Function;
  user: customer;
};
const OrderCard = ({ changeFocus, user }: OrderCardProps) => {
  const [orders, setOrders] = useState<orders[]>([]);
  const [loading, setLoading] = useState(false);
  const { width } = useWindowDimensions();

  useEffect(() => {
    getOrders();
  }, []);

  const getOrders = async () => {
    setLoading(true);

    try {
      GET_REQUEST<orders[]>(ORDERS_PATH, { users: user.id })
        .then(setOrders)
        .catch((error) => ErrorHandler(error.response))
        .finally(() => setLoading(false));
    } catch (error) {
      ErrorHandler(error.response);
    }
  };

  return (
    <>
      <Skeleton loading={loading} active key={user.id}>
        {orders.length > 0 ? (
          <Row
            gutter={[24, 24]}
            className={"grid"}
            style={{ flexDirection: width < 700 ? "column" : "row" }}
          >
            {orders.map((order) => (
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={8}
                xl={8}
                xxl={6}
                key={order.id}
                onClick={changeFocus.bind(this, order.id, order.name)}
              >
                <Card
                  key={order.id}
                  title={order.name}
                  className={"customer-order-detail-card"}
                  style={{ textAlign: "center" }}
                >
                  <table id="customer-order-detail-table">
                    <tbody>
                      <tr>
                        <th>Created on</th>
                        <td>{order.date}</td>
                      </tr>
                      <tr>
                        <th>Project</th>
                        <td>{order.project}</td>
                      </tr>
                      <tr>
                        <th>Description</th>
                        <td>{order.description}</td>
                      </tr>
                      <tr>
                        <th>Place</th>
                        <td>{order.place}</td>
                      </tr>
                    </tbody>
                  </table>
                </Card>
              </Col>
            ))}
          </Row>
        ) : (
          <Empty
            description="No orders found"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
      </Skeleton>
    </>
  );
};
