// @ts-ignore
import React, { useEffect, useState } from "react";
import { Empty } from "antd";
import { media } from "../Shared/Interface";
import { GET_REQUEST, MEDIA_PATH } from "../Shared/Requests";
import { ErrorHandler } from "../Shared/ErrorHandler";
import { MediaItem, mediaTypes } from "../Admin/Order/Media";
import { descend, getContentType } from "../Shared/CommonFunctions";
import { API_INSTANCE } from "../../axios";
import { message } from "antd";
import useWindowDimensions from "../../Hooks/useWindowDimensions";
import { Skeleton } from "antd";
import { Button } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { ascend } from "../Shared/CommonFunctions";
import ReactDOM from "react-dom";

type MediaProps = {
  sn: string;
  itn: string;
};

/**
 * Display all the media of an selected item and visualization
 * @param sn Item's identifier for filter the media
 * @returns {React.FC}
 */
const Media = ({ sn, itn }: MediaProps) => {
  const [media, setMedia] = useState<media[]>([]);
  const [loading, setLoading] = useState(false);
  const { width } = useWindowDimensions();
  const [asc, setAsc] = useState(true);
  const [setup, setSetup] = useState(false);

  useEffect(() => {
    getMedia();
  }, []);

  useEffect(() => {
    setup && sortByName(true);
  }, [setup]);

  useEffect(() => {
    console.log(media);
    
   media.length > 0 && ReactDOM.render(
      <Button
        onClick={() => sortByName(false)}
        icon={asc ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
      >
        {asc ? `Sort By Name` : "Sort By Name"}
      </Button>,
      document.getElementById("sort")
    );

    return () => {
      ReactDOM.unmountComponentAtNode(document.getElementById("sort"));
    };
  }, [media, asc]);

  /**
   * get all the media filter by using {@param sn} and set the media state
   */
  const getMedia = async () => {
    setLoading(true);
    try {
      GET_REQUEST<media[]>(MEDIA_PATH, { items: sn, item_types: itn })
        .then((res) => {
          setMedia(res);
          setSetup(true);
        })
        .catch((error) => console.log(error))
        .finally(() => setLoading(false));
    } catch (error) {
      ErrorHandler(error.response);
    }
  };

  /**
   * Using {@param id} open the media in a new tab
   * @param id selected media id
   */
  const viewMedia = async (id) => {
    setLoading(true);
    try {
      let response = await API_INSTANCE.get(`/presignGetObject/${id}`, {
        headers: {
          Authorization: "Bearer " + window.localStorage.getItem("JWT"),
        },
      });
      response.status === 201 &&
        window.open(response.data) &&
        setLoading(false);
    } catch (error) {
      message.warning("Media didn't found!");
    }
  };

  /**
   * Sort the media by name
   */
  const sortByName = (setup) => {
    let sortedData =
      asc === true
        ? media.sort((a, b) => ascend(a, b, "name"))
        : media.sort((a, b) => descend(b, a, "name"));

    setMedia(sortedData);

    setAsc(!asc);

    setup === true && setSetup(false);
  };

  return (
    <>
      <Skeleton loading={loading} active key={sn}>
        {media.length > 0 ? (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: `repeat(auto-fill, minmax(${
                width > 600 ? "200px" : "150px"
              }, 1fr))`,
            }}
          >
            {/* <Image.PreviewGroup>
              <Image width={200} src="" />
              <Image width={200} src="" />
            </Image.PreviewGroup> */}

            {media.map((item) => (
              <MediaItem
                key={item.id}
                icon={
                  !item.thumbnail ? (
                    mediaTypes[getContentType(item.name)]
                  ) : (
                    <img
                      src={"data:image/jpg;base64," + item.thumbnail}
                      className="thumbnail"
                      alt="thumbnail"
                    /> // <img src={urlCreator.createObjectURL(item.thumbnail)}
                  )
                }
                title={item.name}
                onClickView={() => viewMedia(item.id)}
                // clickViewIcon={<EyeOutlined style={{ color: "gray" }} />}
              />
            ))}
          </div>
        ) : (
          <Empty
            description="No media found"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
      </Skeleton>
    </>
  );
};

export default Media;
