import { orders, item, itemTypes, customer } from './Interface'

/**
 * check if any of values in an object is set or not
 * @param obj Object to verify
 * @returns {Boolean}
 */
export const checkProperties = (obj: orders | item | itemTypes | customer | any) => {
    for (const key in obj) {
        if (obj[key] === null || obj[key] === '' || obj[key] === undefined)
            return false
    }
    return true
}

/**
 * Function for reset an object
 * @param obj object to reset
 * @param setObj function to reset values
 */
export const resetValues = (obj: Object, setObj: Function) => {
    for (const prop of Object.getOwnPropertyNames(obj)) {
        if (prop !== 'date' && prop !== '0') setObj({ [prop]: '' })
        else continue

        if (prop === 'date') setObj({ date: defaultDate() })
        else continue
    }
}

/**
 * Function for transform file to base64
 * @param {file} File the file to transform
 * @returns {Promise} a promise
 */
export const toBase64 = (file: Blob) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
    })

/**
 * return the today's date as a string
 * @returns date as a string
 */
export const defaultDate = () => {
    const today: Date = new Date()
    const date: string = 'Y-m-d'
        .replace('Y', String(today.getFullYear()))
        .replace('m', String(today.getMonth() + 1))
        .replace('d', String(today.getDate()))

    return date
}

/**
 * Function for get content type and declare is it image, pdf, video or other
 * @param {fileName} string file name to verify
 * @returns {String} content type
 */
export const getContentType = (fileName: string) => {
    const exe: string[] = fileName.split('.')
    const type = exe[exe.length - 1].toLowerCase().toString()

    if (type === 'png' || type === 'jpg' || type === 'jpeg') return 'img'
    else if (type === 'pdf') return 'pdf'
    else if (type === 'mp4' || type == 'avi' || type == 'mov') return 'video'
    else return 'other'
}

export const descend = (a, b, sortItem) => {
  if (a[sortItem].toLowerCase() < b[sortItem].toLowerCase()) {
    return -1;
  }
  if (a[sortItem].toLowerCase() > b[sortItem].toLowerCase()) {
    return 1;
  }
  return 0;
};

export const ascend = (a, b, sortItem) => {
  if (a[sortItem].toLowerCase() < b[sortItem].toLowerCase()) {
    return -1;
  }
  if (a[sortItem].toLowerCase() > b[sortItem].toLowerCase()) {
    return 1;
  }
  return 0;
};

export const isEmailValid = (email: string) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}