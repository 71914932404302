import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Orders from "./Customer";
import Login from "./Login";
import Profile from "./Profile";
import Admin from "./Admin";
import Items from "./Admin/Order/Items";
import { useAuthState } from "./Shared/AuthContext/AuthContext";
import ItemList from "./Customer/ItemList";
import Users from "./Admin/Users";
import { InitialLogin } from "./Profile/InitialLogin";
import ResetPassword from "./ResetPassword";

/**
 * The component where handle privileges and paths
 * @returns React.FC
 */
const Router = () => {
  const pathname = window.location.pathname;
  const { isAdmin, user }: any = useAuthState();

  return (
    <BrowserRouter>
      <Switch>
        {user !== "" ? (
          <>
            {user.psw_changed === 0 ? (
              <>
                <Redirect to="/profile/initialLogin" push={true} />
                <Route path="/profile/initialLogin" component={InitialLogin} />
              </>
            ) : isAdmin ? (
              <>
                <Route exact path="/profile/:key" component={Profile} />
                <Route exact path="/orders" component={Orders} />
                <Route
                  exact
                  path="/orders/:order_id/:order_name"
                  component={ItemList}
                />
                <Route exact path="/admin/:key" component={Admin} />
                <Route
                  exact
                  path="/:key/client/:client_id/:client_name"
                  component={Users}
                />
                <Route
                  exact
                  path="/admin/:order_id/:order_name"
                  component={Items}
                />
                <Route
                  exact
                  path="/orders/login/:username/:password/:order_id/:order_name"
                  component={Orders}
                />
                {pathname === "/" && <Redirect to="/orders" />}
              </>
            ) : (
              <>
                <Route exact path="/profile/:key" component={Profile} />
                <Route exact path="/orders" component={Orders} />
                <Route
                  exact
                  path="/orders/:order_id/:order_name"
                  component={ItemList}
                />
                <Route
                  exact
                  path="/orders/login/:username/:password/:order_id/:order_name"
                  component={Orders}
                />
                {pathname === "/" && <Redirect to="/orders" />}
              </>
            )}
          </>
        ) : (
          <>
            {pathname.split("/")[1] === "reset_password" ? (
              <Route exact path="/reset_password/:user_id/:password" component={ResetPassword} />
            ) : (
              <>
                <div
                  style={{
                    position: "absolute",
                    width: "100vw",
                    height: "100vh",
                    zIndex: 9999,
                  }}
                >
                  <Login />
                </div>
                <Route
                  exact
                  path="/orders/login/:username/:password/:order_id/:order_name"
                  component={Orders}
                />
              </>
            )}
          </>
        )}
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
