import React, { MouseEventHandler, useEffect, useState } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import {
  Button,
  PageHeader,
  Modal,
  Form,
  Input,
  message,
  Select,
  Empty,
  Typography,
  Tooltip,
} from "antd";
import { useHistory, useParams } from "react-router-dom";
import { Layout, Loader } from "../../Shared/Layout";
import { ErrorHandler } from "../../Shared/ErrorHandler";
import { item, itemTypes } from "../../Shared/Interface";
import { checkProperties, resetValues } from "../../Shared/CommonFunctions";
import {
  GET_REQUEST,
  API_REQUEST,
  ITEMS_PATH,
  ITEM_TYPES_PATH,
  UPDATE,
  DELETE,
  POST,
} from "../../Shared/Requests";
import Media from "./Media";
// @ts-ignore
import plus from "../../../Resources/plus.png";
// @ts-ignore
import folderThumbnail from "../../../Resources/folder2.png";
import useWindowDimensions from "../../../Hooks/useWindowDimensions";

const { Option } = Select;

/**
 * child of Order Admin/Order/Orders.tsx
 * Items of selected Order
 * @returns {React.FC} show items of an order
 */
const Items = () => {
  const { order_id, order_name }: any = useParams();
  const h = useHistory();
  const [history, setHistory] = useState("");
  const subs = ["Manage item", "Manage media"];
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [itemTypes, setItemTypes] = useState([]);
  const [input, setInput] = useState<item>({ sn: "", item_type_name: "" });
  const [toEdit, setToEdit] = useState("");
  const { width } = useWindowDimensions();
  /**
   * Get items and Item types
   */
  useEffect(() => {
    try {
      getItems();
      getItemTypes();
    } catch (error) {
      ErrorHandler(error.response);
    }
  }, []);

  /**
   * API call for get item types,
   * and set Item types
   */
  const getItemTypes = async () => {
    setLoading(true);

    GET_REQUEST<itemTypes[]>(ITEM_TYPES_PATH)
      .then(setItemTypes)
      .catch((err) => ErrorHandler(err.response))
      .finally(() => setLoading(false));
  };

  /**
   * API call for get item,
   * and set Items
   */
  const getItems = async () => {
    setLoading(true);

    GET_REQUEST<item[]>(ITEMS_PATH, { orders: order_id })
      .then((data) => setItems(data.filter((i) => i.order_id == order_id)))
      .catch((err) => ErrorHandler(err.response))
      .finally(() => setLoading(false));
  };

  /**
   * change component between Item and Media
   * @param focus to understand on which component selected
   */
  const changeFocus = (focus) => setHistory(focus);

  /**
   * come back from Media to Item
   */
  const back = () => setHistory("");

  /**
   * Set the state of inputs for a new Items
   * @param e the event of onInput.
   */
  const handleInput = (e) =>
    setInput({ ...input, [e.target.name]: e.target.value });

  /**
   * Set the state of inputs with new item type selected
   * @param e the event of onSelect.
   */
  const selectItemType = (item_type_name) =>
    setInput({ ...input, item_type_name });

  /**
   * set the state of isModalVisible to true
   */
  const showModal = () => setIsModalVisible(true);

  /**
   * Using selected item's data open up a model pre filled with original data.
   * and set edit modal visible to true
   * @param id set the toEdit with the selected item id
   * @param sn for set input
   * @param item_type_name for set input
   */
  const showEditModal = (sn, item_type_name) => {
    setToEdit(sn);
    setInput({
      sn,
      item_type_name,
    });
    setIsEditModalVisible(true);
  };

  /**
   * Set the isModalVisible to false and reset the state of input
   */
  const handleCancel = () => {
    resetValues(input, setInput);
    setIsModalVisible(false);
  };

  /**
   * Set the isModalVisible to false and reset the state of inputEdit
   */
  const handleEditCancel = () => {
    setInput({ sn: "", item_type_name: "" });
    setIsEditModalVisible(false);
  };

  /**
   * Submit the new Item
   * @param close parameter which indicate is admin adding one or more Item together
   */
  const submitItems = async (close = true) => {
    if (checkProperties(input)) {
      setButtonLoading(true);

      try {
        API_REQUEST(ITEMS_PATH, POST, { order_id, ...input })
          .then((data) => {
            setItems([...items, data]);
            close && setIsModalVisible(false);
            message.success("Successful");
            resetValues(input, setInput);
          })
          .catch((error) => ErrorHandler(error.response))
          .finally(() => setButtonLoading(false));
      } catch (error) {
        ErrorHandler(error.response);
      }
    } else {
      message.warning("Please check your inputs");
    }
  };

  /**
   * Submit the edit Item
   * Update the state with new values
   */
  const submitEditItems = async () => {
    if (checkProperties(input)) {
      setButtonLoading(true);
      try {
        API_REQUEST(ITEMS_PATH, UPDATE, { ...input, order_id }, toEdit)
          .then((data) => {
            setItems(
              items.map((item) =>
                item.sn === toEdit ? { ...input, order_id } : item
              )
            );
            setIsEditModalVisible(false);
            message.success("Successful");
            resetValues(input, setInput);
          })
          .catch((error) => {
            if (error.response.status === 304) {
              setIsEditModalVisible(false);
            }
            ErrorHandler(error.response);
          })
          .then(() => setButtonLoading(false));
      } catch (error) {
        ErrorHandler(error.response);
      }
    } else {
      message.warning("Please check your inputs");
    }
  };

  /**
   * Ask the admin to confirm the delete before actual deletion
   * @param id get the id of the media to delete
   */
  const confirmDelete = (id) => {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content:
        "All the media under this item will be deleted permanently, Are you sure?",
      okText: "Yes, Delete",
      cancelText: "No",
      onOk: () => submitDelete(id),
    });
  };

  /**
   * Submit the deletion
   * @param id pass the id into submit delete from confirmDelete
   */
  const submitDelete = async (id) => {
    setButtonLoading(true);

    try {
      API_REQUEST(ITEMS_PATH, DELETE, null, id)
        .then((data) => {
          setItems(items.filter((data) => data.sn !== id));
          message.success("Item successfully deleted");
        })
        .catch((error) => {
          ErrorHandler(error.response, "item");
        });
    } catch (error) {
      ErrorHandler(error.response);
    } finally {
      setButtonLoading(false);
    }
  };

  const sortStyles = (): React.CSSProperties => {
    if (width < 450) {
      return {};
    } else {
      return {
        position: "absolute",
        right: "0",
        top: "50%",
        transform: "translateY(-50%)",
      };
    }
  };

  return (
    <Layout>
      <div id="item">
        <PageHeader
          title={
            <div>
              <ArrowLeftOutlined
                style={{
                  fontSize: "1rem",
                  marginRight: "1rem",
                }}
                onClick={() =>
                  history.length > 0 ? back() : h.push("/admin/3")
                }
              />
              {history.length === 0 ? order_name : history}
              <div id="sort" style={sortStyles()} />
            </div>
          }
          subTitle={subs[history !== "" ? 1 : 0]}
        />

        {history.length === 0 ? (
          <Folders
            key="items"
            showEditModal={showEditModal}
            showModal={showModal}
            changeFocus={changeFocus}
            dataSource={items}
            confirmDelete={confirmDelete}
            loading={loading}
          />
        ) : (
          <>
            <Media
              key="media"
              item={items.filter((i) => i.sn === history)}
              itemType={null}
            />
          </>
        )}

        <InputModal
          title="Add new Item"
          isModalVisible={isModalVisible}
          handleCancel={handleCancel}
          handleItem={submitItems}
          input={input}
          handleInput={handleInput}
          loading={loading}
          itemTypes={itemTypes}
          selectItemType={selectItemType}
        />

        <InputModal
          title="Edit item"
          isModalVisible={isEditModalVisible}
          handleCancel={handleEditCancel}
          handleItem={submitEditItems}
          input={input}
          handleInput={handleInput}
          loading={buttonLoading}
          itemTypes={itemTypes}
          selectItemType={selectItemType}
        />
      </div>
    </Layout>
  );
};

export default Items;

type FoldersProps = {
  showModal: MouseEventHandler<HTMLDivElement>;
  changeFocus: any;
  dataSource: item[];
  showEditModal: Function;
  confirmDelete: any;
  loading: boolean;
};
const Folders = ({
  showModal,
  changeFocus,
  dataSource,
  showEditModal,
  confirmDelete,
  loading,
}: FoldersProps) => {
  const { height, width } = useWindowDimensions();
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: `repeat(auto-fill, minmax(${
          width > 600 ? "200px" : "150px"
        }, 1fr))`,
      }}
    >
      <div className={"folder"} onClick={showModal}>
        <div
          className="thumbnail-background"
          // style={{ paddingBottom: "2vh" }}
        >
          <img
            src={plus}
            alt=""
            className="thumbnail-placeholder"
            style={{ transform: "scale(.65)" }}
          />
        </div>
      </div>

      {dataSource.length !== 0 ? (
        dataSource.map((item) => (
          <Action
            key={item.sn}
            id={item.sn}
            showEditModal={() => showEditModal(item.sn, item.item_type_name)}
            changeFocus={changeFocus}
            icon={
              <img
                src={folderThumbnail}
                alt=""
                className="thumbnail-placeholder"
                style={{ opacity: ".75" }}
              />
            }
            title={item.sn}
            confirmDelete={() => confirmDelete(item.sn)}
          />
        ))
      ) : (
        <>
          {loading ? (
            <Loader />
          ) : (
            <div className={"folder"} onClick={showModal}>
              <div className="thumbnail-background">
                <Empty
                  description="No item found"
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const Action = ({
  id,
  changeFocus,
  icon,
  title,
  showEditModal,
  confirmDelete,
}) => {
  const { Paragraph } = Typography;

  return (
    <div className={"folder"}>
      <span>
        <div
          className="thumbnail-background "
          style={{ height: "75px" }}
          onClick={() => changeFocus(title)}
        >
          {icon}
        </div>
        <Tooltip overlay={title} placement={"top"}>
          <Paragraph
            style={{ width: "100%", textAlign: "center", marginBottom: "0" }}
            ellipsis={{
              rows: 1,
              expandable: false,
            }}
          >
            {title}
          </Paragraph>
        </Tooltip>
      </span>
      <div>
        <Button
          type={"link"}
          style={{ color: "green" }}
          onClick={showEditModal}
        >
          <EditOutlined />
        </Button>
        <Button type={"link"} onClick={() => confirmDelete(id)}>
          <DeleteOutlined />
        </Button>
      </div>
    </div>
  );
};

type InputModalProps = {
  title: string;
  isModalVisible: boolean;
  handleCancel: VoidFunction;
  handleItem: any;
  selectItemType: any;
  input: item;
  itemTypes: itemTypes[];
  handleInput: any;
  loading?: boolean;
};
const InputModal = ({
  title,
  isModalVisible,
  handleCancel,
  handleItem,
  selectItemType,
  itemTypes,
  input,
  handleInput,
  loading,
}: InputModalProps) => {
  return (
    <Modal
      title={
        <Typography.Title level={3} style={{ margin: "0" }}>
          {title}
        </Typography.Title>
      }
      visible={isModalVisible}
      onCancel={handleCancel}
      maskClosable={false}
      onOk={handleItem}
      okText={title === "Edit item" ? "Done" : "Add and Close"}
      okButtonProps={{ loading: loading }}
    >
      <Form id="item" labelCol={{ span: 6 }} wrapperCol={{ span: 20 }}>
        <Form.Item label="Item name (SN)">
          <Input
            name="sn"
            value={input.sn}
            onChange={handleInput}
            required
            disabled={title === "Edit item"}
          />
        </Form.Item>
        <Form.Item label="Item type">
          <Select
            showSearch
            onChange={selectItemType}
            value={input.item_type_name}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {itemTypes &&
              itemTypes.map((it) => (
                <Option key={it.name} value={it.name}>
                  {it.name}
                </Option>
              ))}
          </Select>
          {/* <Input name="item_type_name" value={input.item_type_name} onChange={handleInput} required /> */}
        </Form.Item>
        {title !== "Edit item" && (
          <Form.Item style={{ justifyContent: "flex-end" }}>
            <Button
              style={{ float: "right" }}
              onClick={() => handleItem(false)}
              loading={loading}
            >
              Add and continue
            </Button>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};
