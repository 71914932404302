import React from 'react'
import ChangePassword from './ChangePassword';
import { useAuthState } from '../Shared/AuthContext/AuthContext';
// @ts-ignore
import logoUrl from "../../Resources/Logo.png";

export const InitialLogin = () => {
    const { isAdmin, user }: any = useAuthState();

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "5vw",
          height: "100vh",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "5vw",
          }}
        >
          <img src={logoUrl} alt="logo" style={{ width: "50%" }} />
          <div
            style={{
              width: "100%",
            }}
          >
            <ChangePassword uid={user.id} initial={true} />
          </div>
        </div>
      </div>
    );
}
