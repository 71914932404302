import {
  Input,
  Button,
  Table,
  Form,
  Typography,
  message,
  Modal,
  Select,
  Tooltip,
  PageHeader,
  Tag,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";
import { customer, orders, user } from "../Shared/Interface";
import {
  ExclamationCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  CopyTwoTone,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { ErrorHandler } from "../Shared/ErrorHandler";
import {
  ascend,
  checkProperties,
  descend,
  resetValues,
  isEmailValid,
} from "./../Shared/CommonFunctions";
import {
  API_REQUEST,
  DELETE,
  EMAIL_PATH,
  GET_REQUEST,
  ORDERS_PATH,
  POST,
  UPDATE,
  USERS_PATH,
} from "../Shared/Requests";
import { useAuthState } from "../Shared/AuthContext/AuthContext";
import useWindowDimensions from "./../../Hooks/useWindowDimensions";
import { MobileTable } from "./Clients";
import { useParams } from "react-router";
import { Layout } from "../Shared/Layout";
import { useHistory } from "react-router-dom";

const { TextArea } = Input;

const { Option } = Select;

const usersTypes = [
  {
    role: "admin",
    value: "Admin",
  },
  {
    role: "customer",
    value: "Customer",
  },
];

interface editData {
  username?: string;
  email?: string;
  role?: string;
  unhashed_password?: string;
  orders?: any[];
}

/**
 * Child component under Admin/index.tsx
 * @param dataSource customer[] from the index
 * @param setDataSource from the index to update the customer state
 * @param loading for set loading to the table if on index still doing the fetch
 * @returns {React.FC} Customers table
 */
const Users = () => {
  const [orders, setOrders] = useState([]);
  const { client_id, client_name }: any = useParams();
  const [isNewUserVisible, setIsNewUserVisible] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [newUser, setNewUser] = useState<editData>({});
  const [inputEdit, setInputEdit] = useState({
    id: "",
    username: "",
    email: "",
    orders: [],
    role: "",
  });
  const [input, setInput] = useState({
    username: "",
    email: "",
    orders: [],
    role: "",
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const { width } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const h = useHistory();
  const { user }: any = useAuthState();
  const [pageSize, setPageSize] = useState(20);
  const [filteredDS, setFilteredDS] = useState([]);

  /**
   * This useEffect get Users
   */
  useEffect(() => {
    try {
      getUsers();
      getOrders();
    } catch (error) {
      ErrorHandler(error.response);
    }
    return () => {
      setDataSource([]);
    };
  }, [client_id]);

  /**
   * In this async function get the orders and set the state
   */
  const getOrders = async () => {
    setLoading(true);

    GET_REQUEST<orders[]>(ORDERS_PATH)
      .then((data) => {
        setOrders(data);
      })
      .catch((err) => ErrorHandler(err.response))
      .finally(() => setLoading(false));
  };

  /**
   * In this async function get the customers and set the state
   */
  const getUsers = async () => {
    setLoading(true);

    GET_REQUEST<customer[]>(USERS_PATH, { customers: client_id })
      .then(setDataSource)
      .catch((err) => ErrorHandler(err.response))
      .finally(() => setLoading(false));
  };

  /**
   * Set the state of inputs for a new order
   * @param e the event of onInput.
   */
  const handleInput = (e) =>
    setInput({ ...input, [e.target.name]: e.target.value });

  /**
   * Set the state of inputs for a edit order
   * @param e  the event of onInput.
   */
  const handleEditInput = (e) =>
    setInputEdit({ ...inputEdit, [e.target.name]: e.target.value });

  /**
   * Set the state of inputs with new user-type selected
   * @param e the event of onSelect.
   */
  const selectRole = (role) => setInput({ ...input, role });

  const handleOrdersInput = (value) => {
    setInput({ ...input, orders: value });
  };

  const handleOrdersInputEdit = (value) => {
    setInputEdit({ ...inputEdit, orders: value });
  };

  const selectEditRole = (role) => setInputEdit({ ...inputEdit, role });

  /**
   * set the state of isModalVisible to true
   */
  const showModal = () => setIsModalVisible(true);

  /**
   * Using selected order's data open up a model pre filled with original data.
   * Set the state of the date selected to edit
   * @param data is the selected user details
   */
  const showEditModal = (data) => {
    setInputEdit(data);
    setIsEditModalVisible(true);
  };

  /**
   * Set the isModalVisible to false and reset the state of input
   */
  const handleCancel = () => {
    delete input.orders;
    resetValues(input, setInput);
    setInput({ ...input, orders: [] });
    setIsModalVisible(false);
  };

  /**
   * Set the isEditModalVisible to false and reset the state of inputEdit
   */
  const handleEditCancel = () => {
    resetValues(inputEdit, setInputEdit);
    setInputEdit({ ...inputEdit });
    setIsEditModalVisible(false);
  };

  /**
   * Ask the admin to confirm the delete before actual deletion
   * @param id get the id of the user to delete
   */
  const confirm = (id) => {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure, you want to delete this user?",
      okText: "Yes, Delete",
      cancelText: "No",
      onOk: () => submitDelete(id),
    });
  };

  /**
   * Ask the admin to confirm the delete before actual deletion
   * @param id get the id of the user to delete
   */
  const submitDelete = async (id) => {
    API_REQUEST(USERS_PATH, DELETE, null, id)
      .then(() => {
        setDataSource(dataSource.filter((data) => data.id !== id));
        message.success("User successfully deleted");
      })
      .catch((error) => ErrorHandler(error.response))
      .finally(() => setButtonLoading(false));
  };

  /**
   * Submit the new user
   * @param close parameter which indicate is admin adding one or more user together
   */
  const handleNewUser = async (close = true) => {
    if (input.username === "") {
      message.error("Username is required");
      return;
    }
    if (input.role === "") {
      message.error("Role is required");
      return;
    }

    // setButtonLoading(true);

    input.email =
      isEmailValid(input.username) && input.email === ""
        ? input.username
        : input.email;

    if (input.email !== "") {
      API_REQUEST(USERS_PATH, POST, input, null, { customers: client_id })
        .then((data) => {
          resetValues(input, setInput);
          close && setIsModalVisible(false);
          setDataSource([...dataSource, data]);
          setNewUser(data);

          API_REQUEST(EMAIL_PATH, POST, {
            template: "FAI_NEW_USER_CREDENTIALS",
            to: input.email,
            payload: {
              username: data.username,
              password: data.unhashed_password,
            },
          })
            .then(() => {
              message.success("User credentials successfully sent");
            })
            .catch((error) => ErrorHandler(error.response));

          // setIsNewUserVisible(true);
          message.success("User successfully added");
        })
        .catch((error) => ErrorHandler(error.response))
        .finally(() => setButtonLoading(false));
    } else {
      message.error("An email is required");
    }
  };

  /**
   * submit the data to edit
   */
  const handleEditOk = async () => {
    setButtonLoading(true);
    let data: editData = {};
    data.username = inputEdit.username;
    data.email = inputEdit.email;
    data.role = inputEdit.role;
    data.orders = inputEdit.orders;

    if (data.role !== "" && data.username !== "") {
      API_REQUEST(USERS_PATH, UPDATE, data, inputEdit.id)
        .then(() => {
          delete inputEdit.email;
          delete inputEdit.orders;
          setIsEditModalVisible(false);
          setDataSource(
            dataSource.map((user) =>
              user.id === inputEdit.id ? { ...data, id: inputEdit.id } : user
            )
          );
          resetValues(inputEdit, setInputEdit);
          message.success("Successful");
        })
        .catch((error) => {
          if (error.response.status === 304) {
            setIsEditModalVisible(false);
          }
          ErrorHandler(error.response);
        })
        .finally(() => setButtonLoading(false));
    } else {
      setButtonLoading(false);
      message.error("No user detail to edit!");
    }
  };

  const copyPassword = (password) => {
    navigator.clipboard.writeText(password);
    message.success("password copied to clipboard");
  };

  /**
   * Columns of the orders table
   */
  const columns = [
    {
      title: "Name",
      dataIndex: "username",
      showSorterTooltip: false,
      sorter: (a, b, c) =>
        c === "ascend" ? ascend(a, b, "username") : descend(a, b, "username"),
    },
    {
      title: "email",
      dataIndex: "email",
      showSorterTooltip: false,
      sorter: (a, b, c) =>
        c === "ascend" ? ascend(a, b, "email") : descend(a, b, "email"),
    },
    {
      title: "Assigned Orders",
      width: "30%",
      ellipsis: true,
      render: (data) =>
        orders.map((o) =>
          data.orders.map(
            (d) =>
              o.id === d && (
                <>
                  <Tag color="red">{o.name}</Tag>
                </>
              )
          )
        ),
    },
    {
      title: "Role",
      width: "10%",
      showSorterTooltip: false,
      sorter: (a, b, c) =>
        c === "ascend" ? ascend(a, b, "role") : descend(a, b, "role"),
      render: (data) => usersTypes.map((c) => c.role === data.role && c.value),
    },
    {
      title: "Action",
      width: "10%",
      render: (data: user) => (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Tooltip
            placement="top"
            title={
              data.username === "Exhibition"
                ? "User can't be edited"
                : "Edit User"
            }
          >
            <Button
              type={"link"}
              style={{
                color: data.username === "Exhibition" ? "gray" : "green",
              }}
              onClick={() => showEditModal(data)}
              disabled={data.username === "Exhibition"}
            >
              <EditOutlined />
            </Button>
          </Tooltip>
          <Tooltip
            placement="top"
            title={
              data.username === "Exhibition" || data.id === user.id
                ? "User can't be deleted"
                : "Delete User"
            }
          >
            <Button
              type={"link"}
              onClick={() => confirm(data.id)}
              disabled={data.username === "Exhibition" || data.id === user.id}
            >
              <DeleteOutlined />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

    const searchByName = (e) => {
      setFilteredDS(
        dataSource.filter((item) => {
          return item.username.toLowerCase().includes(e.target.value.toLowerCase()) || item.email.toLowerCase().includes(e.target.value.toLowerCase()) || item.role.toLowerCase().includes(e.target.value.toLowerCase());
        })
      );
    };

  return (
    <Layout>
      <div id="user" style={{ padding: "1vh" }}>
        {/* <Divider /> */}
        <div className="admin-title">
          <PageHeader
            title={
              <div>
                <Typography.Title
                  level={width < 1024 ? 3 : 2}
                  style={{
                    float: "left",
                    flex: "",
                    margin: "0",
                    padding: "0",
                  }}
                >
                  <ArrowLeftOutlined
                    style={{ fontSize: "1rem", marginRight: "1rem" }}
                    onClick={() => h.goBack()}
                  />
                  {client_name}'s users
                </Typography.Title>
              </div>
            }
          />
          <div
            style={{
              flex: "2",
              display: "flex",
              gap: "10px",
              justifyContent: "flex-end",
            }}
          >
            <Input
              allowClear
              onChange={searchByName}
              placeholder={"Search on username, email or role"}
              style={{ width: "50%" }}
            />
            <Button className="fixed-button" size={"large"} onClick={showModal}>
              Add new user
            </Button>
          </div>
        </div>

        {width < 1024 ? (
          <MobileTable
            dataSource={filteredDS.length !== 0 ? filteredDS : dataSource}
            columns={columns}
            loading={loading}
          />
        ) : (
          <Table
            dataSource={filteredDS.length !== 0 ? filteredDS : dataSource}
            columns={columns}
            loading={loading}
            pagination={{
              showSizeChanger: true,
              onShowSizeChange(current, size) {
                setPageSize(size);
              },
              pageSize: pageSize,
            }}
          />
        )}
        {/* Edit Modal */}
        <InputModal
          title={"Edit user"}
          loading={buttonLoading}
          isModalVisible={isEditModalVisible}
          handleCancel={handleEditCancel}
          handleNewUser={handleEditOk}
          input={inputEdit}
          handleInput={handleEditInput}
          selectRole={selectEditRole}
          orders={orders}
          client_id={client_id}
          handleOrdersInput={handleOrdersInputEdit}
        />

        {/* Insert Modal */}
        <InputModal
          title={"Add users"}
          loading={buttonLoading}
          isModalVisible={isModalVisible}
          handleCancel={handleCancel}
          handleNewUser={handleNewUser}
          input={input}
          handleInput={handleInput}
          selectRole={selectRole}
          orders={orders}
          client_id={client_id}
          handleOrdersInput={handleOrdersInput}
        />
      </div>

      {/* <Modal
        title="New user details"
        centered
        visible={isNewUserVisible}
        onCancel={() => setIsNewUserVisible(false)}
        footer={[
          <Button key="back" onClick={() => setIsNewUserVisible(false)}>
            Close
          </Button>,
        ]}
      >
        <p>
          Username: <b>{newUser.username}</b>
        </p>
        <p>
          Password: <b>{newUser.unhashed_password}</b>
          <Tooltip placement="top" title={"Copy password"}>
            <Button
              type={"link"}
              onClick={() => copyPassword(newUser.unhashed_password)}
            >
              {" "}
              <CopyTwoTone />{" "}
            </Button>
          </Tooltip>
        </p>
        <p>
          Role: <b>{newUser.role}</b>
        </p>
      </Modal> */}
    </Layout>
  );
};

export default Users;

type InputModalProps = {
  title: any;
  isModalVisible: any;
  handleCancel: any;
  handleNewUser: any;
  input: any;
  handleInput: any;
  loading: any;
  selectRole?: any;
  orders?: any;
  client_id?: string;
  handleOrdersInput?: any;
};
export const InputModal = ({
  title,
  isModalVisible,
  handleCancel,
  handleNewUser,
  input,
  handleInput,
  loading,
  selectRole,
  orders,
  client_id,
  handleOrdersInput,
}: InputModalProps) => {
  //@ts-ignore
  const { user } = useAuthState();

  const check = () => (checkProperties(input) ? true : false);

  return (
    <Modal
      centered
      title={
        <Typography.Title level={3} style={{ margin: "0" }}>
          {title}
        </Typography.Title>
      }
      visible={isModalVisible}
      onCancel={handleCancel}
      maskClosable={false}
      onOk={check && handleNewUser}
      okText={title === "Edit user" ? "Done" : "Add and close"}
      okButtonProps={{ loading }}
    >
      <Form id="client" labelCol={{ span: 5 }} wrapperCol={{ span: 20 }}>
        {selectRole ? (
          <>
            <Form.Item label="Username">
              <Input
                name="username"
                value={input.username}
                onChange={handleInput}
                disabled={title === "Edit user"}
              />
            </Form.Item>
            <Form.Item label="E-mail">
              <Input
                type="email"
                name="email"
                value={input.email}
                onChange={handleInput}
                required
              />
            </Form.Item>
            <Form.Item label="Orders">
              <Select
                mode={"tags"}
                style={{ width: "100%" }}
                onChange={handleOrdersInput}
                value={input.orders}
              >
                {orders.map(
                  (o) =>
                    o.customer_id === client_id && (
                      <Option value={o.id}>{o.name}</Option>
                    )
                )}
              </Select>
            </Form.Item>
            <Form.Item label="Role">
              <Select
                showSearch
                onChange={selectRole}
                value={input.role}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option key={"admin"} value={"admin"}>
                  Admin
                </Option>
                <Option key={"customer"} value={"customer"}>
                  Customer
                </Option>
              </Select>
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item label="Name">
              <Input
                name="name"
                value={input.name}
                onChange={handleInput}
                required
              />
            </Form.Item>
            <Form.Item label="Note">
              <TextArea
                rows={2}
                style={{ width: "100%" }}
                name="note"
                value={input.note}
                onChange={handleInput}
                required
              />
            </Form.Item>
          </>
        )}
        {title !== "Edit user" && (
          <Form.Item style={{ justifyContent: "flex-end" }}>
            <Button
              style={{ float: "right" }}
              onClick={() => check && handleNewUser(false)}
            >
              Add and continue
            </Button>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};
