import axios from "axios";
interface Headers{
    'Content-Type': string
}
export let headers: Headers = {
    'Content-Type': 'application/json',
};

export const API_INSTANCE = axios.create({
    baseURL: 'https://9sxoitdurl.execute-api.eu-west-1.amazonaws.com/default',
    headers
})