import {
  Button,
  Divider,
  Table,
  Typography,
  Modal,
  Form,
  Input,
  message,
  Tooltip,
  PageHeader,
} from "antd";
import React, { useState } from "react";
import {
  ExclamationCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  FileSearchOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { ErrorHandler } from "../Shared/ErrorHandler";
import {
  ascend,
  checkProperties,
  descend,
  resetValues,
} from "./../Shared/CommonFunctions";
import {
  UPDATE,
  API_REQUEST,
  DELETE,
  ITEM_TYPES_PATH,
  POST,
} from "../Shared/Requests";
import { itemTypes } from "../Shared/Interface";
import useWindowDimensions from "./../../Hooks/useWindowDimensions";
import { MobileTable } from "./Clients";
import Media from "./Order/Media";

const { TextArea } = Input;

type ItemTypesProps = {
  dataSource: itemTypes[];
  setDataSource: Function;
  loading: boolean;
};

/**
 * Child component under Admin/index.tsx
 * @param dataSource is the item types from the parent component
 * @param setDataSource base on update of Item types, update the parent
 * @returns {React.FC} Item types table
 */
const ItemTypes = ({ dataSource, setDataSource, loading }: ItemTypesProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [toEdit, setToEdit] = useState("");
  const [input, setInput] = useState({ name: "", description: "" });
  const [inputEdit, setInputEdit] = useState({ name: "", description: "" });
  const [showMedia, setShowMedia] = useState("");
  const { width } = useWindowDimensions();
  const [pageSize, setPageSize] = useState(20);
  const [filteredDS, setFilteredDS] = useState([]);

  /**
   * Set the state of inputs for a new Item type
   * @param e the event of onInput.
   */
  const handleInput = (e) =>
    setInput({ ...input, [e.target.name]: e.target.value });

  /**
   * Set the state of inputs for a edit Item type
   * @param e  the event of onInput.
   */
  const handleInputEdit = (e) =>
    setInputEdit({ ...inputEdit, [e.target.name]: e.target.value });

  /**
   * set the state of isModalVisible to true
   */
  const showModal = () => setIsModalVisible(true);

  /**
   * Set the isModalVisible to false and reset the state of input
   */
  const handleCancel = () => {
    resetValues(input, setInput);
    setIsModalVisible(false);
  };

  /**
   * Set the isEditModalVisible to false and reset the state of inputEdit
   */
  const handleEditCancel = () => {
    resetValues(inputEdit, setInputEdit);
    setIsEditModalVisible(false);
  };

  /**
   * Ask the admin to confirm the delete before actual deletion
   * @param id get the id of the Item type to delete
   */
  const confirmDelete = (id) => {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure, you want to delete this item type?",
      okText: "Yes, Delete",
      cancelText: "No",
      onOk: () => submitDelete(id),
    });
  };

  /**
   * Submit the deletion
   * @param id pass the id into submit delete from confirmDelete
   */
  const submitDelete = async (id) => {
    API_REQUEST(ITEM_TYPES_PATH, DELETE, null, id)
      .then((data) => {
        setDataSource(dataSource.filter((data) => data.name !== id));
        message.success("Customer successfully deleted");
      })
      .catch((error) => ErrorHandler(error.response, "item type"))
      .finally(() => setButtonLoading(false));
  };

  /**
   * Submit the new Item type
   * @param close parameter which indicate is admin adding one or more Item types together
   */
  const handleNewItemTypes = async (close = true) => {
    setButtonLoading(true);
    if (checkProperties(input)) {
      API_REQUEST(
        ITEM_TYPES_PATH,
        POST,
        { description: input.description },
        input.name
      )
        .then((data) => {
          setDataSource([...dataSource, data]);
          resetValues(input, setInput);
          close && setIsModalVisible(false);
          message.success("Successful");
        })
        .catch((error) => ErrorHandler(error.response))
        .finally(() => setButtonLoading(false));
    } else {
      message.warning("Please check your inputs");
      setButtonLoading(false);
    }
  };

  /**
   * Using selected item type's data open up a model pre filled with original data.
   * Set the toEdit with the selected item type id
   * @param data is the selected item type details
   */
  const showEditModal = (data) => {
    setToEdit(data.name);
    setInputEdit(data);
    setIsEditModalVisible(true);
  };

  /**
   * submit the edit item types
   */
  const submitEditItemTypes = async () => {
    setButtonLoading(true);
    if (checkProperties(inputEdit)) {
      API_REQUEST(
        ITEM_TYPES_PATH,
        UPDATE,
        { description: inputEdit.description },
        toEdit
      )
        .then((data) => {
          setDataSource(
            dataSource.map((item) =>
              item.name === toEdit ? { ...inputEdit, name: toEdit } : item
            )
          );
          resetValues(inputEdit, setInputEdit);
          setIsEditModalVisible(false);
          message.success("Successful");
        })
        .catch((error) => {
          if (error.response.status === 304) {
            setIsEditModalVisible(false);
          }
          ErrorHandler(error.response);
        })
        .finally(() => setButtonLoading(false));
    } else {
      message.warning("Please check your inputs");
      setButtonLoading(false);
    }
  };

  /**
   * Columns of the item types table
   */
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      showSorterTooltip: false,
      sorter: (a, b, c) =>
        c === "ascend" ? ascend(a, b, "name") : descend(a, b, "name"),
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Action",
      width: "12%",
      render: (data) => (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, 33%)",
            justifyItems: "center",
          }}
        >
          <Tooltip placement="top" title={"Edit Item type"}>
            <Button
              type={"link"}
              style={{ padding: "0", color: "green" }}
              onClick={() => showEditModal(data)}
            >
              <EditOutlined />
            </Button>
          </Tooltip>
          <Tooltip placement="top" title={"Delete Item type"}>
            <Button
              type={"link"}
              style={{ padding: "0" }}
              onClick={() => confirmDelete(data.name)}
            >
              <DeleteOutlined />
            </Button>
          </Tooltip>
          <Tooltip placement="top" title={"Manage Media"}>
            <Button
              type={"link"}
              style={{ color: "grey", padding: "0" }}
              onClick={() => setShowMedia(data.name)}
            >
              <FileSearchOutlined />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  const sortStyles = (): React.CSSProperties => {
    if (width < 450) {
      return {};
    } else {
      return {
        position: "absolute",
        right: "0",
        top: "50%",
        transform: "translateY(-50%)",
      };
    }
  };

    const searchByName = (e) => {
      setFilteredDS(
        dataSource.filter((item) => {
          return (
            item.name.toLowerCase().includes(e.target.value.toLowerCase()) 
          );
        })
      );
    };

  return (
    <>
      {!showMedia ? (
        <>
          <div className="admin-title">
            <Typography.Title
              level={width < 1024 ? 3 : 2}
              style={{
                float: "left",
                flex: "",
                margin: "0",
                padding: "0",
              }}
            >
              Item Types
            </Typography.Title>

            <div
              style={{
                flex: "2",
                display: "flex",
                gap: "10px",
                justifyContent: "flex-end",
              }}
            >
              <Input
                allowClear
                onChange={searchByName}
                placeholder={"Search on your item type"}
                style={{ width: "50%" }}
              />
              <Button
                className="fixed-button"
                size={"large"}
                onClick={showModal}
              >
                Add new item type
              </Button>
            </div>
          </div>
          {width < 1024 ? (
            <MobileTable
              dataSource={filteredDS.length !== 0 ? filteredDS : dataSource}
              columns={columns}
              loading={loading}
            />
          ) : (
            <Table
              dataSource={filteredDS.length !== 0 ? filteredDS : dataSource}
              columns={columns}
              loading={loading}
              pagination={{
                showSizeChanger: true,
                onShowSizeChange(current, size) {
                  setPageSize(size);
                },
                pageSize: pageSize,
              }}
            />
          )}
        </>
      ) : (
        <>
          <PageHeader
            title={
              <div>
                <ArrowLeftOutlined
                  style={{ fontSize: "1rem", marginRight: "1rem" }}
                  onClick={() => setShowMedia("")}
                />
                {showMedia}
                <div id="sort" style={sortStyles()} />
              </div>
            }
            subTitle={"Manage media"}
          />
          <Media key="media" item={null} itemType={showMedia} />
        </>
      )}
      <InputModal
        title={"Add item type"}
        isModalVisible={isModalVisible}
        handleCancel={handleCancel}
        handleItemTypes={handleNewItemTypes}
        input={input}
        handleInput={handleInput}
        loading={buttonLoading}
      />

      <InputModal
        title={"Edit item type"}
        isModalVisible={isEditModalVisible}
        handleCancel={handleEditCancel}
        handleItemTypes={submitEditItemTypes}
        input={inputEdit}
        handleInput={handleInputEdit}
        loading={buttonLoading}
      />
    </>
  );
};

export default ItemTypes;

type InputModalProps = {
  title: string;
  isModalVisible: boolean;
  handleCancel: VoidFunction;
  handleItemTypes: any;
  input: itemTypes;
  handleInput: any;
  loading: boolean;
};

const InputModal = ({
  title,
  isModalVisible,
  handleCancel,
  handleItemTypes,
  input,
  handleInput,
  loading,
}: InputModalProps) => {
  return (
    <Modal
      centered
      title={
        <Typography.Title level={3} style={{ margin: "0" }}>
          {title}
        </Typography.Title>
      }
      visible={isModalVisible}
      onCancel={handleCancel}
      maskClosable={false}
      onOk={handleItemTypes}
      okText={title === "Edit item type" ? "Done" : "Add and Close"}
      okButtonProps={{ loading }}
    >
      <Form id="itemTypes" labelCol={{ span: 5 }} wrapperCol={{ span: 20 }}>
        <Form.Item label="Name">
          <Input
            name="name"
            value={input.name}
            onChange={handleInput}
            required
            disabled={title === "Edit item type"}
          />
        </Form.Item>
        <Form.Item label="Description">
          <TextArea
            rows={2}
            style={{ width: "100%" }}
            name="description"
            value={input.description}
            onChange={handleInput}
            required
          />
        </Form.Item>
        {title !== "Edit item type" && (
          <Form.Item style={{ justifyContent: "flex-end" }}>
            <Button
              style={{ float: "right" }}
              onClick={() => handleItemTypes(false)}
            >
              Add and continue
            </Button>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};
