let user = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : "";
let admin =
  localStorage.getItem("user") &&
  JSON.parse(localStorage.getItem("user")).role === "admin";

let token = localStorage.getItem("JWT") ? localStorage.getItem("JWT") : "";

export const initialState = {
  user: "" || user,
  token: "" || token,
  isAdmin: false || admin,
  psw_changed: 0,
  loading: false,
  errorMessage: null,
};

export const AuthReducer = (initialState, action) => {
  switch (action.type) {
    case "REQUEST_LOGIN":
      return {
        ...initialState,
        loading: true,
      };
    case "LOGIN_SUCCESS":
      return {
        ...initialState,
        user: action.payload.data,
        isAdmin: false || action.payload.data.role === "admin",
        psw_changed: 0 || action.payload.data.psw_changed,
        token: action.payload.JWT,
        loading: false,
      };
    case "LOGOUT":
      return {
        ...initialState,
        user: "",
        token: "",
        isAdmin: false,
        psw_changed:0
      };
    case "LOGIN_ERROR":
      return {
        ...initialState,
        loading: false,
        isAdmin: false,
        psw_changed: 0,
        errorMessage: action.error,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
