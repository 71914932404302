import { Link, Redirect } from "react-router-dom";
import { Typography, Divider, Tabs } from "antd";
import ChangePassword from "./ChangePassword";
import { Layout } from "./../Shared/Layout";
import { useAuthState } from "../Shared/AuthContext/AuthContext";
import { useHistory, useParams } from "react-router";

const Profile = () => {
  const { user }: any = useAuthState();
  const history = useHistory();
  const { key }: any = useParams();

  return (
    <Layout>
      {user.psw_changed === 1 && history.location.pathname === "/profile/initialLogin" && <Redirect to="/orders" />}

      <Typography.Title level={1}>Profile</Typography.Title>

      {user && (
        <>
          <Divider />
          {user.username !== "Exhibition" ? (
            <Tabs
              className="profile-card-tabs"
              type="line"
              defaultActiveKey={key}
              onChange={(key) => history.push(`/profile/${key}`)}
            >
              <Tabs.TabPane tab="Details" key="1">
                <div id={"tab-1"}>
                  <Typography.Title level={2} style={{ margin: "0" }}>
                    Welcome {user.username}!
                  </Typography.Title>

                  <Typography.Paragraph>
                    <Link to="/orders">Click here to see your Orders</Link>
                  </Typography.Paragraph>
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Change Password" key="2">
                <ChangePassword uid={user.id} />
              </Tabs.TabPane>
            </Tabs>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "50%",
              }}
            >
              <Typography.Title level={2} style={{ margin: "0" }}>
                Welcome {user.username}!
              </Typography.Title>

              <Typography.Paragraph>
                <Link to="/orders">Click here to see your Orders</Link>
              </Typography.Paragraph>
            </div>
          )}
        </>
      )}
    </Layout>
  );
};

export default Profile;
