import React from 'react'
import ReactDOM from 'react-dom'
import Router from './Components/Router'
import './styles/index.scss'
import { AuthProvider } from './Components/Shared/AuthContext';

/**
 * The function which render the react component on index.html
 */
ReactDOM.render(
    <AuthProvider>
        <Router />
    </AuthProvider>
    , document.getElementById('root'))