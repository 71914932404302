import { useEffect, useState } from "react";
//@ts-ignore
import logoUrl from "../../Resources/FAI_logo.png";
import {
  Layout as L,
  Menu,
  Button,
  Tooltip,
  Divider,
  Modal,
  Dropdown,
} from "antd";
import {
  UserOutlined,
  OrderedListOutlined,
  RightOutlined,
  LeftOutlined,
  LogoutOutlined,
  SettingOutlined,
  ExclamationCircleOutlined,
  MenuUnfoldOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import { logout, useAuthDispatch, useAuthState } from "./AuthContext";
import useWindowDimensions from "../../Hooks/useWindowDimensions";
import { GET_REQUEST, ORDERS_PATH } from "./Requests";
import { orders } from "./Interface";

export const Layout = ({ children }) => {
  const { isAdmin }: any = useAuthState();
  const [collapsed, setCollapsed] = useState(true);
  const dispatch = useAuthDispatch();
  const { width } = useWindowDimensions();
  const h = useHistory();

  const toggleCollapsed = () => setCollapsed((state) => !state);

  const confirmLogout = () => {
    Modal.confirm({
      title: "Are you sure you want to logout?",
      icon: <ExclamationCircleOutlined />,
      okText: "Logout",
      onOk: () => logout(dispatch),
      cancelText: "Cancel",
      centered: true,
    });
  };

  const dropdownMenu = (
    <Menu id="mobile-menu">
      <MenuItem icon={<OrderedListOutlined />} path="/orders" text="Orders" />
      <MenuItem icon={<UserOutlined />} path="/profile/1" text="Profile" />
      {isAdmin && (
        <MenuItem icon={<SettingOutlined />} path="/admin/1" text="Admin" />
      )}
      <Menu.Item
        style={{ color: "white", background: "red", height: "50px" }}
        icon={<LogoutOutlined />}
        onClick={confirmLogout}
      >
        <span>Logout</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <UserValidity>
      {width > 1024 ? (
        <>
          {" "}
          <Tooltip
            title={collapsed ? "Expand Menu" : "Collapse Menu"}
            placement="right"
          >
            <Button
              className="trigger"
              style={{
                left: !collapsed && "200px",
              }}
              icon={collapsed ? <RightOutlined /> : <LeftOutlined />}
              onClick={toggleCollapsed}
            />
          </Tooltip>
          <L className="siteLayout">
            <L.Sider className={"side-bar"} collapsed={collapsed}>
              <Menu>
                <MenuItem
                  icon={<OrderedListOutlined />}
                  path="/orders"
                  text="Orders"
                />
                <MenuItem
                  icon={<UserOutlined />}
                  path="/profile/1"
                  text="Profile"
                />
                {isAdmin && (
                  <MenuItem
                    icon={<SettingOutlined />}
                    path="/admin/1"
                    text="Admin"
                  />
                )}
              </Menu>
              <div className="sidebar-logo">
                <Menu>
                  <Divider />

                  <Menu.Item
                    style={{ color: "black" }}
                    icon={<LogoutOutlined />}
                    onClick={confirmLogout}
                  >
                    <span>Logout</span>
                  </Menu.Item>
                </Menu>
                <Divider />
                <img src={logoUrl} alt="logo" width={"90%"} />
              </div>
            </L.Sider>
            <L.Content className={"main-content"}> {children} </L.Content>
          </L>
        </>
      ) : (
        <>
          <L.Header id="mobile">
            <div onClick={() => h.push("/orders")} className="logo">
              <img src={logoUrl} alt="logo" />
            </div>
            <Dropdown
              className={"dropdown-icon"}
              overlay={dropdownMenu}
              trigger={["click"]}
            >
              <span onClick={toggleCollapsed}>
                {collapsed ? <MenuUnfoldOutlined /> : <CloseOutlined />}
              </span>
            </Dropdown>
          </L.Header>
          <L.Content className={"main-content"} style={{ paddingTop: "10vh" }}>
            {" "}
            {children}{" "}
          </L.Content>
        </>
      )}
    </UserValidity>
  );
};

const MenuItem = ({ icon, path, text }): JSX.Element => {
  const history = useHistory();

  return (
    <>
      <Menu.Item
        key={text}
        className={
          (path.includes(history.location.pathname.split("/")[1]) &&
            "selected") + " side-bar-item"
        }
        icon={icon}
      >
        <Link to={path}>{text}</Link>
      </Menu.Item>
    </>
  );
};

export const Loader = ({ text = "" }) => {
  const { width } = useWindowDimensions();

  return (
    <>
      {
        <div className={width > 1024 ? "preloader" : "preloader-mobileTable"}>
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <h1 id="a">{text}</h1>
          </div>
        </div>
      }
    </>
  );
};

export const UserValidity = ({ children }) => {
  const { user }: any = useAuthState();
  const dispatch = useAuthDispatch();
  const history = useHistory();

  useEffect(() => {
    getOrders();
  }, []);

  const getOrders = async () => {
    let x = localStorage.getItem("login_at");
    let loggedIn = new Date(x).getTime();
    let now = new Date().getTime();
    console.log(x);
    if (x !== null) {
      if ((now - loggedIn) / (1000 * 60 * 60) < 8) {
        let time = new Date().toUTCString();
        localStorage.setItem("login_at", time);
        GET_REQUEST<orders[]>(ORDERS_PATH, { users: user.id })
          .then((data) => data)
          .catch((error) => {
            error.response.status === 401 &&
              Modal.confirm({
                title: "Your session has expired, please log back in!",
                icon: <ExclamationCircleOutlined />,
                okText: "Ok",
                onOk: () => logout(dispatch) && history.push(`/`),
                okCancel: false,
                centered: true,
              });
          });
      } else {
        Modal.confirm({
          title: "Your session has expired, please log back in!",
          icon: <ExclamationCircleOutlined />,
          okText: "Ok",
          onOk: () => logout(dispatch) && history.push(`/`),
          okCancel: false,
          centered: true,
        });
      }
    }
  };

  return <>{children}</>;
};
