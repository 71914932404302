import React, { ChangeEventHandler, useState } from "react";
import { customer, orders } from "../../Shared/Interface";
import {
  Typography,
  Table,
  Button,
  Modal,
  Form,
  Input,
  DatePicker,
  message,
  Select,
  Tooltip,
  Tag,
} from "antd";
import {
  ExclamationCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  QrcodeOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { ErrorHandler } from "../../Shared/ErrorHandler";
import { Link, useHistory } from "react-router-dom";
import {
  ascend,
  checkProperties,
  defaultDate,
  descend,
  resetValues,
} from "../../Shared/CommonFunctions";
import QRCode from "qrcode-svg";
import useWindowDimensions from "./../../../Hooks/useWindowDimensions";
import {
  API_REQUEST,
  DELETE,
  ORDERS_PATH,
  POST,
  UPDATE,
} from "../../Shared/Requests";
import { MobileTable } from "../Clients";

const { Option } = Select;

const url = "https://app.faicarvico.com";
//const url = "https://192.168.28.62:1234";

type OrdersProps = {
  dataSource: orders[];
  customers: customer[];
  users: any;
  setDataSource: Function;
  loading: boolean;
};

/**
 * Child component under Admin/index.tsx
 * @param dataSource is the orders from the parent component
 * @param customer is the orders from the parent component for give the user possibility to select the customer
 * @param setDataSource base on update of orders, update the parent
 * @returns {React.FC}
 */
const Orders = ({
  dataSource,
  customers,
  users,
  setDataSource,
  loading,
}: OrdersProps) => {
  const history = useHistory();
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [toEdit, setToEdit] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [input, setInput] = useState<orders>({
    name: "",
    project: "",
    description: "",
    date: defaultDate(),
    place: "",
    customer_id: "",
  });
  const [inputEdit, setInputEdit] = useState<orders>({
    name: "",
    project: "",
    description: "",
    date: defaultDate(),
    place: "",
    customer_id: "",
  });
  const { width } = useWindowDimensions();
  const [filteredDS, setFilteredDS] = useState([])
  const [pageSize, setPageSize] = useState(20);

  /**
   * Set the state of inputs for a new order
   * @param e the event of onInput.
   */
  const handleInput = (e) =>
    setInput({ ...input, [e.target.name]: e.target.value });

  /**
   * Set the state of inputs for a edit order
   * @param e  the event of onInput.
   */
  const handleInputEdit = (e) =>
    setInputEdit({ ...inputEdit, [e.target.name]: e.target.value });

  /**
   * Set the state of date for a new order
   * @param date get the date from the date picker
   */
  const setDate = (date) => setInput({ ...input, date });

  /**
   * Set the state of date for a edit order
   * @param date get the date from the date picker
   */
  const setEditDate = (date) => setInputEdit({ ...inputEdit, date });

  /**
   * Set the state of select customer for a new order
   * @param value selected customer from Select
   * @returns
   */
  const selectCustomer = (value) => setInput({ ...input, customer_id: value });

  /**
   * Set the state of select customer for a edit order
   * @param value selected customer from Select
   * @returns
   */
  const selectEditCustomer = (value) =>
    setInputEdit({ ...inputEdit, customer_id: value });

  /**
   * set the state of isModalVisible to true
   */
  const showModal = () => setIsModalVisible(true);

  /**
   * Using selected order's data open up a model pre filled with original data.
   * Set the state of the date selected to edit
   * @param data is the selected orders details
   */
  const showEditModal = (data: orders) => {
    setToEdit(data.id);
    setDate(data.date);
    setInputEdit(data);
    setIsEditModalVisible(true);
  };

  /**
   * Set the isModalVisible to false and reset the state of input
   */
  const handleCancel = () => {
    resetValues(input, setInput);
    setIsModalVisible(false);
  };

  /**
   * Set the isEditModalVisible to false and reset the state of inputEdit
   */
  const handleEditCancel = () => {
    resetValues(inputEdit, setInputEdit);
    setIsEditModalVisible(false);
  };

  /**
   * Submit the new order
   * @param close parameter which indicate is admin adding one or more orders together
   */
  const submitNewOrder = async (close = true) => {
    setButtonLoading(true);
    if (checkProperties(input)) {
      API_REQUEST(ORDERS_PATH, POST, input)
        .then((data) => {
          console.log(data);
          setDataSource([...dataSource, data]);
          close && setIsModalVisible(false);
          resetValues(input, setInput);
          message.success("Successful");
        })
        .catch((error) => ErrorHandler(error.response))
        .finally(() => setButtonLoading(false));
    } else {
      message.warning("Fill in all the fields!");
      setButtonLoading(false);
    }
  };

  /**
   * submit the data to edit
   */
  const submitEditOrder = async () => {
    if (checkProperties(inputEdit)) {
      setButtonLoading(true);
      let data = inputEdit;
      let users = data.users;
      delete data.id;
      delete data.users;

      try {
        API_REQUEST(ORDERS_PATH, UPDATE, data, toEdit)
          .then((data) => {
            setDataSource(
              dataSource.map((order) =>
                order.id === toEdit
                  ? { ...inputEdit, id: toEdit, users }
                  : order
              )
            );
            setIsEditModalVisible(false);
            resetValues(inputEdit, setInputEdit);
            message.success("Successful");
          })
          .catch((error) => {
            if (error.response.status === 304) {
              setIsEditModalVisible(false);
            }
            ErrorHandler(error.response);
          });
      } catch (error) {
        setButtonLoading(false);
      } finally {
        setButtonLoading(false);
      }
    } else {
      message.warning("Fill in all the fields!");
      setButtonLoading(false);
    }
  };

  /**
   * Ask the admin to confirm the delete before actual deletion
   * @param id get the id of the order to delete
   */
  const confirmDelete = (id) => {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure, you want to delete this order?",
      okText: "Yes, Delete",
      cancelText: "No",
      onOk: () => submitDelete(id),
    });
  };

  /**
   * Submit the deletion
   * @param id pass the id into submit delete from confirmDelete
   */
  const submitDelete = async (id) => {
    try {
      API_REQUEST(ORDERS_PATH, DELETE, null, id)
        .then((data) => {
          setDataSource(dataSource.filter((d) => d.id !== id));
          message.success("Order successfully deleted");
        })
        .catch((error) => {
          ErrorHandler(error.response, "order");
        });
    } catch (error) {
      ErrorHandler(error.response);
    } finally {
      setButtonLoading(false);
    }
  };

  const downloadQRCode = (id, name) => {
    // `${url}/orders/login/Exhibition/kl&88V5O!&@ai!mZ/${id}/${name}`
    const link = `${url}/orders/${id}/${name}`;
    let qrCode = new QRCode(link).svg();

    let svg = qrCode.split("</svg>");

    qrCode =
      svg[0] +
      `
      <text x="25%" y="256" font-size="1.5em">
          Ref: ${name}
        </text>  
      ` +
      "</svg>";

    var urlSVG =
      "data:image/svg+xml;charset=utf-8," + encodeURIComponent(qrCode);

    var downloadLink = document.createElement("a");
    downloadLink.href = urlSVG;
    downloadLink.download = `${name}.svg`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  /**
   * Columns of the orders table
   */
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      showSorterTooltip: false,
      sorter: (a, b, c) =>
        c === "ascend" ? ascend(a, b, "date") : descend(a, b, "date"),
    },
    {
      title: "FAI Job",
      dataIndex: "name",
      key: "name",
      showSorterTooltip: false,
      sorter: (a, b, c) =>
        c === "ascend" ? ascend(a, b, "name") : descend(a, b, "name"),
    },
    {
      title: "Project",
      dataIndex: "project",
      key: "project",
      showSorterTooltip: false,
      sorter: (a, b, c) =>
        c === "ascend" ? ascend(a, b, "project") : descend(a, b, "project"),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },

    {
      title: "Place",
      dataIndex: "place",
      key: "place",
      showSorterTooltip: false,
      sorter: (a, b, c) =>
        c === "ascend" ? ascend(a, b, "place") : descend(a, b, "place"),
    },
    {
      title: "Client",
      render: (data: orders) => {
        let x = customers.filter((c) => c.id === data.customer_id)[0].name;

        return (
          <i>
            <u>
              <Link to={`/admin/client/${data.customer_id}/${x}`}>{x}</Link>
            </u>
          </i>
        );
      },
      showSorterTooltip: false,
      sorter: (a, b, c) =>
        c === "ascend"
          ? ascend(
              customers.filter((c) => c.id === a.customer_id)[0],
              customers.filter((c) => c.id === b.customer_id)[0],
              "name"
            )
          : descend(
              customers.filter((c) => c.id === a.customer_id)[0],
              customers.filter((c) => c.id === b.customer_id)[0],
              "name"
            ),
    },
    {
      title: "Users",
      render: (data) =>
        users.map((u) =>
          data.users.map(
            (d) => u.id === d && <Tag color="red">{u.username}</Tag>
          )
        ),
    },
    {
      title: "Action",
      width: "12%",
      render: (data: orders) => (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, 25%)",
            justifyItems: "center",
          }}
        >
          <Tooltip placement="top" title={"Download QR"}>
            <Button
              type={"link"}
              style={{ padding: "0" }}
              onClick={() => downloadQRCode(data.id, data.name)}
            >
              {" "}
              <QrcodeOutlined style={{ color: "black" }} />{" "}
            </Button>
          </Tooltip>
          <Tooltip placement="top" title={"Edit Order"}>
            <Button
              type={"link"}
              style={{ color: "green", padding: "0" }}
              onClick={() => showEditModal(data)}
            >
              {" "}
              <EditOutlined />{" "}
            </Button>
          </Tooltip>
          <Tooltip placement="top" title={"Delete Order"}>
            <Button
              type={"link"}
              style={{ padding: "0" }}
              onClick={() => confirmDelete(data.id)}
            >
              {" "}
              <DeleteOutlined />{" "}
            </Button>
          </Tooltip>
          <Tooltip placement="top" title={"Manage Items"}>
            <Button
              type={"link"}
              style={{ color: "grey", padding: "0" }}
              onClick={() => history.push(`/admin/${data.id}/${data.name}`)}
            >
              {" "}
              <FileSearchOutlined />{" "}
            </Button>
          </Tooltip>
        </div>
      ),
      key: "id",
    },
  ];

  const searchByName = (e) => {
    setFilteredDS(
      dataSource.filter((item) => {
        return (
          item.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
          item.place.toLowerCase().includes(e.target.value.toLowerCase()) ||
          item.project.toLowerCase().includes(e.target.value.toLowerCase())
        );
      })
    );
  };

  return (
    <>
      <div className="admin-title">
        <Typography.Title
          level={width < 1024 ? 3 : 2}
          style={{
            float: "left",
            flex: "",
            margin: "0",
            padding: "0",
          }}
        >
          Orders List
        </Typography.Title>

        <div
          style={{
            flex: "2",
            display: "flex",
            gap: "10px",
            justifyContent: "flex-end",
          }}
        >
          <Input
            allowClear
            onChange={searchByName}
            placeholder={"Search on your order, project or place"}
            style={{ width: "50%" }}
          />
          <Button className="fixed-button" size={"large"} onClick={showModal}>
            Add new order
          </Button>
        </div>
      </div>

      {width < 1024 ? (
        <MobileTable
          dataSource={filteredDS.length !== 0 ? filteredDS : dataSource}
          columns={customers.length !== 0 && columns}
          loading={loading}
        />
      ) : (
        <Table
          rowKey="id"
          dataSource={filteredDS.length !== 0 ? filteredDS : dataSource}
          columns={customers.length !== 0 && columns}
          loading={loading}
          pagination={{
            showSizeChanger: true,
            onShowSizeChange(current, size) {
              setPageSize(size);
            },
            pageSize: pageSize,
          }}
        />
      )}
      <InputModal
        key={"new_order"}
        title="New Order"
        isModalVisible={isModalVisible}
        handleCancel={handleCancel}
        handleItemType={submitNewOrder}
        input={input}
        handleInput={handleInput}
        loading={buttonLoading}
        date={input.date}
        setDate={setDate}
        customers={customers}
        selectCustomer={selectCustomer}
      />

      <InputModal
        key={"edit_order"}
        title="Edit Order"
        isModalVisible={isEditModalVisible}
        handleCancel={handleEditCancel}
        handleItemType={submitEditOrder}
        input={inputEdit}
        handleInput={handleInputEdit}
        loading={buttonLoading}
        date={inputEdit.date}
        setDate={setEditDate}
        customers={customers}
        selectCustomer={selectEditCustomer}
      />
    </>
  );
};

export default Orders;

type InputModalProps = {
  title: string;
  isModalVisible: boolean;
  handleCancel: VoidFunction;
  handleItemType: any;
  input: orders;
  handleInput: ChangeEventHandler<HTMLInputElement>;
  loading: boolean;
  date: any;
  setDate?: Function;
  customers: customer[];
  selectCustomer: any;
};
/**
 * The model in use for insert new Order or Edit order
 * @param title is to show user what kind of operation that they use
 * @param {isModalVisible} boolean show and hide modal
 * @param {handleCancel} Function to close the modal
 * @param {handleItemType} Function to submit the values to backend
 * @param {input} orders an object of the inputs in the modal
 * @param {handleInput} Function set up the object with the inserted values from the modal
 * @param {loading} boolean set the loading of the button
 * @param {date} Date order date
 * @param {setDate} Function set order date to input object (from antd the way to get the value is deferent to normal input)
 * @param {customers} customer[] list of possible customers who can make the order
 * @param {selectCustomer} Function set selected customer to input object (from antd the way to get the value is deferent to normal input)
 * @returns {React.FC}
 */
const InputModal = ({
  title,
  isModalVisible,
  handleCancel,
  handleItemType,
  input,
  handleInput,
  loading,
  date,
  setDate,
  customers,
  selectCustomer,
}: InputModalProps) => {
  let value = customers.filter((c) => c.id === input.customer_id);
  const dateFormat = "YYYY-MM-DD";
  return (
    <Modal
      title={
        <Typography.Title level={3} style={{ margin: "0" }}>
          {title}
        </Typography.Title>
      }
      visible={isModalVisible}
      onCancel={handleCancel}
      maskClosable={false}
      onOk={handleItemType}
      okText={title === "Edit Order" ? "Done" : "Add and close"}
      okButtonProps={{ loading }}
    >
      <Form
        id="order"
        onFinish={console.log}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 20 }}
      >
        <Form.Item label="Fai Job">
          <Input
            name="name"
            value={input.name}
            onChange={handleInput}
            required
          />
        </Form.Item>
        <Form.Item label="Project">
          <Input
            name="project"
            value={input.project}
            onChange={handleInput}
            required
          />
        </Form.Item>
        <Form.Item label="Description">
          <Input
            name="description"
            value={input.description}
            onChange={handleInput}
            required
          />
        </Form.Item>
        <Form.Item label="Date">
          <DatePicker
            style={{ width: "100%" }}
            value={moment(date)}
            onChange={(a, b) => setDate(b)}
            defaultValue={moment("2015-01-01", "YYYY-MM-DD")}
          />
        </Form.Item>
        <Form.Item label="Place">
          <Input
            name="place"
            value={input.place}
            onChange={handleInput}
            required
          />
        </Form.Item>
        {/* <Form.Item label="Manufacturer name">
          <Input
            name="manufacturer_name"
            value={input.manufacturer_name}
            onChange={handleInput}
            required
          />
        </Form.Item> */}
        <Form.Item label="Client">
          <Select
            showSearch
            onChange={selectCustomer}
            value={value.length === 1 && value[0].name}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {customers &&
              customers.map((customer) => (
                <Option key={customer.id} value={customer.id}>
                  {customer.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
        {title !== "Edit Order" && (
          <Form.Item style={{ justifyContent: "flex-end" }}>
            <Button
              style={{ float: "right" }}
              onClick={() => handleItemType(false)}
            >
              Add and continue
            </Button>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};
