import { AxiosResponse } from "axios";
import { message} from 'antd';

export const ErrorHandler = (response: AxiosResponse, msg = "") => {
    let status =  response && response.status || "";

    switch (status) {
        case 204:
            message.info("No content!")
            break;
        case 304:
            message.warning("No data has been modified")
            break;
        case 400:
            message.error(response.data.error_message)
            break;
        case 401:
            message.error(response.data.error_message)
            break;
        case 404:
            message.error(response.data.error_message)
            break;
        case 500:
            let x: string = response.data.error_message
                                .split(",")[0]
                                .replace("(", "");

            if (x === '1451') 
                message.error(`You cannot delete this ${msg}! Because it's not empty`)
            else
                message.error(response.data.error_message)
            break;
        default:
            message.warning("Check your internet connection!")
            break;
    }

}