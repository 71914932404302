import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DeleteOutlined,
  DropboxOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  InboxOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import React, { MouseEventHandler, useEffect, useState } from "react";
import {
  Modal,
  Button,
  message,
  Empty,
  Typography,
  Tooltip,
  Progress,
} from "antd";
import {
  API_REQUEST,
  GET_REQUEST,
  MEDIA_PATH,
  DELETE,
} from "../../Shared/Requests";
import { ErrorHandler } from "../../Shared/ErrorHandler";
import { media } from "../../Shared/Interface";
import { Loader } from "../../Shared/Layout";
import {
  ascend,
  descend,
  getContentType,
  toBase64,
} from "../../Shared/CommonFunctions";
import { AntdIconProps } from "@ant-design/icons/lib/components/AntdIcon";
import { API_INSTANCE } from "../../../axios";
import useWindowDimensions from "./../../../Hooks/useWindowDimensions";
// @ts-ignore
import imageThumbnail from "../../../Resources/image-thumbnail-min.png";
// @ts-ignore
import videoThumbnail from "../../../Resources/video-thumbnail-min.png";
// @ts-ignore
import pdfThumbnail from "../../../Resources/pdf-thumbnail-min.png";
// @ts-ignore
import plus from "../../../Resources/plus.png";
// @ts-ignore
import fileThumbnail from "../../../Resources/file.png";
import ReactDOM from "react-dom";

/**
 * Icons for each content type
 */
export const mediaTypes = {
  img: <img src={imageThumbnail} alt="" className="thumbnail-placeholder" />, //<FileImageTwoTone twoToneColor="#0044ff" />,
  pdf: <img src={pdfThumbnail} alt="" className="thumbnail-placeholder" />, //<FilePdfTwoTone twoToneColor="#ff0000" />,
  video: <img src={videoThumbnail} alt="" className="thumbnail-placeholder" />, //<VideoCameraTwoTone twoToneColor="#8822ff" />,
  other: <img src={fileThumbnail} alt="" className="thumbnail-placeholder" />,
};

const { Paragraph } = Typography;

/**
 * child of item Admin/Order/Items.tsx
 * Display the media and user can perform actions on these media
 * @param item item object which media ought to display
 * @returns JSX.Element Media of a given item
 */
const Media = ({ item, itemType }) => {
  const [media, setMedia] = useState([]);
  const [loading, setLoading] = useState(false);
  const [present, setPresent] = useState(null);
  const [showDnD, setShowDnD] = useState(false);
  const { height, width } = useWindowDimensions();
  const [asc, setAsc] = useState(true);
  const [setup, setSetup] = useState(false);

  useEffect(() => {
    getMedia();
    document.ondragover = (e) => {
      e.preventDefault();
    };

    document.ondragleave = (e) => {
      if (e.screenX === 0 && e.screenY === 0) setShowDnD(false);
    };

    document.ondragenter = (e) => {
      e.preventDefault();
      setShowDnD(true);
    };

    document.ondrop = (e) => {
      e.preventDefault();
      handleMediaDrag(e);
      setShowDnD(false);
    };
  }, []);

  useEffect(() => {
    setup && sortByName(true);
  }, [setup]);

  useEffect(() => {
    console.log(media);

    media.length > 0 &&
      ReactDOM.render(
        <Button
          onClick={() => sortByName(false)}
          icon={asc ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
        >
          {asc ? `Sort By Name` : "Sort By Name"}
        </Button>,
        document.getElementById("sort")
      );

    return () => {
      ReactDOM.unmountComponentAtNode(document.getElementById("sort"));
    };
  }, [media, asc]);

  /**
   * automatically activate add new file when user click the add new file icon
   */
  const addFile = () => {
    let file = document.getElementById("media-file");
    file.click();
  };

  /**
   * get all the media of a selected item,
   * the media get filtered in this function
   */
  const getMedia = () => {
    setLoading(true);
    try {
      let fk = item
        ? { items: item[0].sn, item_types: item[0].item_type_name }
        : { item_types: itemType };

      GET_REQUEST<media[]>("/media", fk)
        .then((data) => {
          item
            ? setMedia(data)
            : setMedia(data.filter((i) => i.item_sn === null));

          setSetup(true);
          // if (item) setMedia(data.filter((i) => i.item_sn === item[0].sn));
          // else setMedia(data.filter((i) => i.item_type_name === itemType));
        })
        .catch(console.error)
        .finally(() => setLoading(false));
    } catch (error) {
      console.log(error);
      ErrorHandler(error.response);
    }
  };

  const handleMediaInput = ({ target }) => {
    try {
      uploadMedia(Array.from(target.files));
    } catch (error) {
      throw error;
    } finally {
      target.value = null;
    }
  };

  const handleMediaDrag = ({ dataTransfer }) => {
    try {
      console.log(dataTransfer);
      uploadMedia(Array.from(dataTransfer.files));
    } catch (error) {
      throw error;
    } finally {
      dataTransfer.value = null;
    }
  };

  /**
   * Get the file and transform it to base64,
   * and send to API
   * @param files
   */
  const uploadMedia = (files) => {
    setLoading(true);
    setPresent(0);
    let i = 0;

    try {
      files.forEach(async (file: any) => {
        let name = file.name;
        let type = file.type;
        let content: any = await toBase64(file);
        content = content.split(",")[1];

        if (file) {
          var urlDataString = JSON.stringify({
            name,
            item_sn: itemType ? null : item[0].sn,
            item_type_name: itemType || item[0].item_type_name,
          });

          let res = await fetch(
            "https://9sxoitdurl.execute-api.eu-west-1.amazonaws.com/default/presign/" +
              encodeURIComponent(urlDataString),
            {
              headers: {
                Authorization: "Bearer " + window.localStorage.getItem("JWT"),
                "Content-Type": type,
              },
            }
          );

          let data = await res.json();

          if (res.ok && data.url) {
            let url = data.url;
            var reader = new FileReader();

            reader.onload = async (evt) => {
              await putMedia(evt, name, type, data.key, url);
              i++;

              setPresent(Math.round((i / files.length) * 100));

              if (files.length === i) {
                setLoading(false);
                setPresent(null);
              }
            };

            reader.onerror = (evt) => {
              console.log("error reading file");
            };

            reader.readAsArrayBuffer(file);
          } else {
            message.error(data.error_message);
            ErrorHandler(data);
            setLoading(false);
          }
        }
      });
    } catch (error) {
      ErrorHandler(error.response);
    }
  };

  const putMedia = async (evt, name, type, key, url, maxRetry = 2) => {
    try {
      let retry = true;
      let retryCounter = 0;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", type);

      while (retry) {
        await new Promise((resolve) =>
          setTimeout(resolve, (2 ^ retryCounter) * 100)
        );

        let res = await fetch(url, {
          method: "PUT",
          headers: myHeaders,
          body: evt.target.result,
        });

        if (res.ok) {
          setMedia((media) => [
            ...media,
            {
              id: key,
              name,
              item_sn: itemType ? null : item[0].sn,
              item_type_name: itemType || item[0].item_type_name,
              thumbnail: "",
            },
          ]);

          setSetup(true);

          message.success("Media successfully uploaded");
          retry = false;
        } else {
          retryCounter++;
          retry = maxRetry !== retryCounter;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Ask the admin to confirm the delete before actual deletion
   * @param id get the id of the media to delete
   */
  const confirmDelete = (id) => {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure, you want to delete this media?",
      okText: "Yes, Delete",
      cancelText: "No",
      onOk: () => submitDelete(id),
    });
  };

  /**
   * Submit the deletion
   * @param id pass the id into submit delete from confirmDelete
   */
  const submitDelete = async (id) => {
    setLoading(true);
    try {
      API_REQUEST(MEDIA_PATH, DELETE, null, id)
        .then((data) => {
          setMedia(media.filter((data) => data.id !== id));
          message.success("Media successfully deleted");
        })
        .catch((error) => {
          ErrorHandler(error.response);
        })
        .finally(() => setLoading(false));
    } catch (error) {
      ErrorHandler(error.response);
    }
  };

  /**
   * Open the selected media in the browser in new tab
   * @param id media selected for visualization
   */
  const viewMedia = async (id, title) => {
    setLoading(true);
    try {
      let response = await API_INSTANCE.get(`/presignGetObject/${id}`, {
        headers: {
          Authorization: "Bearer " + window.localStorage.getItem("JWT"),
        },
      });
      if (response.status === 201) {
        setLoading(false);
        window.open(response.data, title);
      }
    } catch (error) {
      message.warning("Media didn't found!");
    }
  };

  /**
   * function that will open the new window with the media
   *
   * @param url url to open
   * @param title tittle of the window
   * @param w width of the window
   * @param h height of the window
   */
  const popupCenter = ({ url, title, w, h }) => {
    const dualScreenLeft =
      window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop =
      window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width;
    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft;
    const top = (height - h) / 2 / systemZoom + dualScreenTop;
    const newWindow = window.open(
      url,
      title,
      `
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
    );

    if (window.focus) newWindow.focus();
  };

  /**
   * Sort the media by name
   */
  const sortByName = (setup) => {
    let sortedData =
      asc === true
        ? media.sort((a, b) => ascend(a, b, "name"))
        : media.sort((a, b) => descend(b, a, "name"));

    setMedia(sortedData);

    setAsc(!asc);

    setup === true && setSetup(false);
  };

  return (
    <>
      {loading && <Loader text={present !== null && `${present}%`} />}

      {showDnD && (
        <div className="ondrag">
          <div>
            <span>DROP HERE</span>
            <InboxOutlined />
          </div>
        </div>
      )}

      <div
        style={{
          display: "grid",
          gridTemplateColumns: `repeat(auto-fill, minmax(${
            width > 600 ? "200px" : "150px"
          }, 1fr))`,
        }}
      >
        <div className={"folder"} onClick={addFile}>
          <div className="thumbnail-background" style={{ height: "150px" }}>
            <img
              src={plus}
              alt="add"
              className="thumbnail-placeholder"
              style={{ transform: "scale(.5)" }}
            />
            <input
              style={{ display: "none" }}
              multiple={true}
              aria-label="a"
              type="file"
              id="media-file"
              onChange={handleMediaInput}
              accept="video/*,image/jpeg,image/png,.pdf,.docx,.pptx"
            />
          </div>
        </div>

        {media.length !== 0 ? (
          media.map((item) => {
            return (
              <MediaItem
                key={item.id}
                sn={item.item_sn}
                itemType={[itemType, item.item_type_name]}
                icon={
                  !item.thumbnail ? (
                    mediaTypes[getContentType(item.name)]
                  ) : (
                    <img
                      src={"data:image/jpg;base64," + item.thumbnail}
                      className="thumbnail"
                      alt="thumbnail"
                    />
                  )
                }
                title={item.name}
                onClickView={() => viewMedia(item.id, item.name)}
                clickViewIcon={<EyeOutlined style={{ color: "gray" }} />}
                onClickDelete={() => confirmDelete(item.id)}
                deleteIcon={<DeleteOutlined />}
              />
            );
          })
        ) : (
          <div className={"folder"}>
            <div className="thumbnail-background">
              <Empty
                description="No media found"
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Media;

type MediaItemProps = {
  icon: AntdIconProps;
  title: string;
  sn?: string;
  itemType?: string[];
  onClickView?: MouseEventHandler<HTMLElement>;
  clickViewIcon?: AntdIconProps;
  onClickDelete?: MouseEventHandler<HTMLElement>;
  deleteIcon?: AntdIconProps;
};
/**
 * Media as an icon Video, Photo or PDF
 * @param icon Media icon
 * @param title Media title
 * @param sn item serial number
 * @param itemType itemType
 * @param onClickView On click open the media on a new tab
 * @param clickViewIcon icon for show media
 * @param onClickDelete On click delete the media
 * @param deleteIcon icon for delete media
 * @returns {React.FC}
 */
export const MediaItem = ({
  icon,
  title,
  sn,
  itemType,
  onClickView,
  clickViewIcon,
  onClickDelete,
  deleteIcon,
}: MediaItemProps) => {
  const name = title.split(".");
  const extension = name[name.length - 1];
  const { width } = useWindowDimensions();

  return (
    <div className={"folder"}>
      <span>
        <Tooltip overlay={title} placement={"top"}>
          <div
            className="thumbnail-background thumbnail-background-style"
            onClick={onClickView}
            style={{ height: "150px" }}
          >
            {sn === null && itemType[0] === null && (
              <div className="thumbnail-background-tag">
                <Tooltip
                  overlay={"Item Type: " + itemType[1]}
                  placement={"right"}
                >
                  <Paragraph
                    style={{ margin: "2px", color: "white" }}
                    ellipsis={{
                      rows: 1,
                      expandable: false,
                    }}
                  >
                    {itemType[1]}
                  </Paragraph>
                </Tooltip>
              </div>
            )}
            {icon}
          </div>
          <Paragraph
            strong
            style={{
              width: "100%",
              textAlign: "center",
              marginTop: "10px",
            }}
            ellipsis={{
              rows: 2,
              expandable: false,
              suffix: "." + extension,
            }}
          >
            {name.pop() && name.concat()}
          </Paragraph>
        </Tooltip>
      </span>
      {(deleteIcon || clickViewIcon) && (
        <div style={{ opacity: width < 1024 && 1 }}>
          {onClickDelete && (
            <Tooltip
              overlay={
                itemType[0] === null && sn === null
                  ? "Delete from item types"
                  : "Delete media"
              }
              placement={"top"}
            >
              <Button
                type={"link"}
                onClick={onClickDelete}
                disabled={itemType[0] === null && sn === null}
              >
                {deleteIcon}
              </Button>
            </Tooltip>
          )}
          {onClickView && (
            <Tooltip overlay={"View media"} placement={"top"}>
              <Button type={"link"} onClick={onClickView}>
                {clickViewIcon}
              </Button>
            </Tooltip>
          )}
        </div>
      )}
    </div>
  );
};
