import { AxiosResponse } from "axios"
import { API_INSTANCE } from "../../axios"

/**
 * API actions
 */
export const POST = "POST";
export const GET = "GET";
export const UPDATE = "UPDATE";
export const DELETE = "DELETE";
export const PUT = "PUT";

/**
 * API end points
 */
export const ITEMS_PATH = "/items";
export const ITEM_TYPES_PATH = "/itemTypes";
export const ORDERS_PATH = "/orders";
export const CUSTOMERS_PATH = "/customers";
export const USERS_PATH = "/users";
export const MEDIA_PATH = "/media";
export const EMAIL_PATH = "/email";

/**
 * @param id media to download
 * @returns http response
 */
export async function POST_REQUEST(path, body): Promise<AxiosResponse<any>> {
    let response: AxiosResponse = await API_INSTANCE.post(path, body, {
        headers: {
            'Authorization': "Bearer " + window.localStorage.getItem('JWT')
        }
    })
    return response
}

/**
 * API call function for get data from database
 * @param path end point of the request
 * @returns data from the response
 */
export async function GET_REQUEST<T>(path, foreign_keys = null) {
    let response: AxiosResponse = await API_INSTANCE.post<T>(path, {
        action: "GET",
        key: null,
        foreign_keys,
        payload: null
    }, {
        headers: {
            'Authorization': "Bearer " + window.localStorage.getItem('JWT')
        }
    })

    return response.data || []
}

/**
 * API POST, Update, DELETE request function
 * @param path end point of the request
 * @param action what to do (POST, UPDATE or DELETE)
 * @param payload the data to save
 * @param key if UPDATE or DELETE, item's key/id
 * @param foreign_keys if exist reference to other tables
 * @returns data from the response
 */
export async function API_REQUEST(path, action, payload = null, key = null, foreign_keys = null) {
    try {
        let response: AxiosResponse = await API_INSTANCE.post(path, {
            action,
            key,
            foreign_keys,
            payload
        }, {
            headers: {
                'Authorization': "Bearer " + window.localStorage.getItem('JWT')
            }
        })
        
        return response.data
    } catch (error) {
        throw error
    }

}


