import React, { useState } from "react";
import {
  Button,
  Modal,
  Table,
  Tooltip,
  Select,
  message,
  Typography,
  Empty,
  Input,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router";
import {
  ascend,
  checkProperties,
  descend,
  resetValues,
} from "../Shared/CommonFunctions";
import Users, { InputModal } from "./Users";
import {
  API_REQUEST,
  CUSTOMERS_PATH,
  DELETE,
  POST,
  UPDATE,
} from "../Shared/Requests";
import { ErrorHandler } from "../Shared/ErrorHandler";
import useWindowDimensions from "./../../Hooks/useWindowDimensions";
import { Loader } from "../Shared/Layout";

const { Option } = Select;

interface editData {
  name?: string;
  note?: string;
}

const Clients = ({ dataSource, setDataSource, loading, orders }) => {
  const [inputEdit, setInputEdit] = useState({
    id: "",
    name: "",
    note: "",
  });
  const history = useHistory();
  const [input, setInput] = useState({ name: "", note: "" });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isUsersModalVisible, setIsUsersModalVisible] = useState(false);
  const [selectedClient, setSelectedClient] = useState({ id: "", name: "" });
  const { width } = useWindowDimensions();
  const [pageSize, setPageSize] = useState(20);
  const [filteredDS, setFilteredDS] = useState([]);

  /**
   * Set the state of inputs for a new order
   * @param e the event of onInput.
   */
  const handleInput = (e) =>
    setInput({ ...input, [e.target.name]: e.target.value });

  /**
   * Set the state of inputs for a edit order
   * @param e  the event of onInput.
   */
  const handleEditInput = (e) =>
    setInputEdit({ ...inputEdit, [e.target.name]: e.target.value });

  /**
   * Using selected order's data open up a model pre filled with original data.
   * Set the state of the date selected to edit
   * @param data is the selected client details
   */
  const showEditModal = (data) => {
    setInputEdit(data);
    setIsEditModalVisible(true);
  };

  /**
   * Ask the admin to confirm the delete before actual deletion
   * @param id get the id of the client to delete
   */
  const confirmDelete = (id) => {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content:
        "Are you sure, you want to delete this client? If you do all the users of this clients will get deleted!",
      okText: "Yes, Delete",
      cancelText: "No",
      onOk: () => submitDelete(id),
    });
  };

  /**
   * Ask the admin to confirm the delete before actual deletion
   * @param id get the id of the client to delete
   */
  const submitDelete = async (id) => {
    API_REQUEST(CUSTOMERS_PATH, DELETE, null, id)
      .then((data) => {
        setDataSource(dataSource.filter((data) => data.id !== id));
        message.success("Customer successfully deleted");
      })
      .catch((error) => ErrorHandler(error.response))
      .finally(() => setButtonLoading(false));
  };

  /**
   * set the state of isModalVisible to true
   */
  const showModal = () => setIsModalVisible(true);

  /**
   * Set the isModalVisible to false and reset the state of input
   */
  const handleCancel = () => {
    resetValues(input, setInput);
    setIsModalVisible(false);
  };

  /**
   * Set the isEditModalVisible to false and reset the state of inputEdit
   */
  const handleEditCancel = () => {
    resetValues(inputEdit, setInputEdit);
    setIsEditModalVisible(false);
  };

  /**
   * submit the data to edit
   */
  const handleEditOk = async () => {
    setButtonLoading(true);
    let data: editData = {};

    data.name = inputEdit.name;
    data.note = inputEdit.note;

    if (checkProperties(inputEdit)) {
      API_REQUEST(CUSTOMERS_PATH, UPDATE, data, inputEdit.id)
        .then((data) => {
          setIsEditModalVisible(false);
          setDataSource(
            dataSource.map((user) =>
              user.id === inputEdit.id ? inputEdit : user
            )
          );
          resetValues(inputEdit, setInputEdit);
          message.success("Successful");
        })
        .catch((error) => {
          if (error.response.status === 304) {
            setIsEditModalVisible(false);
          }
          ErrorHandler(error.response);
        })
        .finally(() => setButtonLoading(false));
    } else {
      setButtonLoading(false);
      message.error("No user detail to edit!");
    }
  };

  /**
   * Submit the new user
   * @param close parameter which indicate is admin adding one or more user together
   */
  const handleNewClient = async (close = true) => {
    setButtonLoading(true);
    if (input.name !== "") {
      API_REQUEST(CUSTOMERS_PATH, POST, input)
        .then((data) => {
          resetValues(input, setInput);
          close && setIsModalVisible(false);
          setDataSource([...dataSource, data]);
          message.success("Successful");
        })
        .catch((error) => ErrorHandler(error.response))
        .finally(() => setButtonLoading(false));
    } else {
      message.warning("Please insert a name");
      setButtonLoading(false);
    }
  };

  const handleUsersModal = (clientId, clientName) => {
    clientId &&
      clientName &&
      setSelectedClient({
        id: clientId,
        name: clientName,
      });
    setIsUsersModalVisible(!isUsersModalVisible);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      showSorterTooltip: false,
      sorter: (a, b, c) =>
        c === "ascend" ? ascend(a, b, "name") : descend(a, b, "name"),
    },
    {
      title: "Note",
      dataIndex: "note",
      key: "note",
    },
    {
      title: "Action",
      width: "12%",
      render: (data) => (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, 33%)",
            justifyItems: "center",
          }}
        >
          <Tooltip
            placement="top"
            title={
              data.name === "DEV" || data.name === "FAI"
                ? "This Client can't be edited"
                : "Edit client"
            }
          >
            <Button
              type={"link"}
              style={{
                color:
                  data.name === "DEV" || data.name === "FAI"
                    ? "#bfbfbf"
                    : "green",
                padding: "0",
              }}
              onClick={() => showEditModal(data)}
              disabled={data.name === "DEV" || data.name === "FAI"}
            >
              {" "}
              <EditOutlined />{" "}
            </Button>
          </Tooltip>
          <Tooltip
            placement="top"
            title={
              data.name === "DEV" || data.name === "FAI"
                ? "This Client can't be deleted"
                : "Delete client"
            }
          >
            <Button
              type={"link"}
              style={{ padding: "0" }}
              onClick={() => confirmDelete(data.id)}
              disabled={data.name === "DEV" || data.name === "FAI"}
            >
              <DeleteOutlined />
            </Button>
          </Tooltip>
          <Tooltip placement="top" title={"Manage Users"}>
            <Button
              type={"link"}
              style={{ color: "grey", padding: "0" }}
              onClick={() =>
                history.push(`/admin/client/${data.id}/${data.name}`)
              }
            >
              <UserSwitchOutlined />
            </Button>
          </Tooltip>
        </div>
      ),
      key: "id",
    },
  ];

  const searchByName = (e) => {
    setFilteredDS(
      dataSource.filter((item) => {
        return item.name.toLowerCase().includes(e.target.value.toLowerCase());
      })
    );
  };

  return (
    <>
      {/* <Divider /> */}
      <div className="admin-title">
        <Typography.Title
          level={width < 1024 ? 3 : 2}
          style={{
            float: "left",
            flex: "",
            margin: "0",
            padding: "0",
          }}
        >
          Clients List
        </Typography.Title>
        <div
          style={{
            flex: "2",
            display: "flex",
            gap: "10px",
            justifyContent: "flex-end",
          }}
        >
          <Input
            allowClear
            onChange={searchByName}
            placeholder={"Search on Client name"}
            style={{ width: "50%" }}
          />
          <Button className="fixed-button" size={"large"} onClick={showModal}>
            Add new client
          </Button>
        </div>
      </div>

      {width < 1024 ? (
        <MobileTable
          dataSource={filteredDS.length !== 0 ? filteredDS : dataSource}
          columns={columns}
          loading={loading}
        />
      ) : (
        <Table
          rowKey={"id"}
          dataSource={filteredDS.length !== 0 ? filteredDS : dataSource}
          columns={columns}
          loading={loading}
          pagination={{
            showSizeChanger: true,
            onShowSizeChange(current, size) {
              setPageSize(size);
            },
            pageSize: pageSize,
          }}
        />
      )}

      {/* <Modal
        visible={isUsersModalVisible}
        onCancel={() => handleUsersModal(null, null)}
        footer={null}
        width={1024}
      >
        <Users
          client_id={selectedClient.id}
          client_name={selectedClient.name}
          orders={orders}
        />
      </Modal> */}

      {/* Add Modal */}
      <InputModal
        title={"Add new client"}
        loading={buttonLoading}
        isModalVisible={isModalVisible}
        handleCancel={handleCancel}
        handleNewUser={handleNewClient}
        input={input}
        handleInput={handleInput}
      />

      {/* Edit Modal */}
      <InputModal
        title={"Edit client"}
        loading={buttonLoading}
        isModalVisible={isEditModalVisible}
        handleCancel={handleEditCancel}
        handleNewUser={handleEditOk}
        input={inputEdit}
        handleInput={handleEditInput}
      />
    </>
  );
};

export default Clients;

export const MobileTable = ({ dataSource, columns, loading = false }) => {
  const { width } = useWindowDimensions();
  return (
    <div className={"mobile-table"}>
      {!loading ? (
        columns && dataSource.length !== 0 ? (
          dataSource.map((ds) => {
            return (
              <article
                className="mobile-table-item"
                style={{ width: width > 750 ? "50%" : "95%" }}
              >
                {columns.map((c) => (
                  <>
                    {c.dataIndex ||
                    c.title === "Assigned Orders" ||
                    c.title === "Users" ||
                    c.title === "Role" ||
                    c.title === "Client" ? (
                      <div className="mobile-table-item-row">
                        <p>
                          <b>{c.title}</b>:
                        </p>
                        <p
                          className={
                            c.title === "Assigned Orders" && "item-cell"
                          }
                        >
                          {c.dataIndex ? ds[c.dataIndex] : c.render(ds)}{" "}
                        </p>
                      </div>
                    ) : (
                      <div className="mobile-table-item-row mobile-table-item-action">
                        <p>{c.dataIndex ? ds[c.dataIndex] : c.render(ds)}</p>
                      </div>
                    )}
                  </>
                ))}
              </article>
            );
          })
        ) : (
          <Empty
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            description="No data found"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )
      ) : (
        <Loader />
      )}
    </div>
  );
};
