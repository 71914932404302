// @ts-ignore
import React, { useState } from "react";
import { Card, Col, Empty, PageHeader, Row } from "antd";
import { item, orders } from "../Shared/Interface";
import { ErrorHandler } from "../Shared/ErrorHandler";
import { useEffect } from "react";
import { Typography } from "antd";
import { GET_REQUEST, ITEMS_PATH, ORDERS_PATH } from "../Shared/Requests";
import { useParams } from "react-router-dom";
import Media from "./Media";
import { Layout } from "./../Shared/Layout";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import useWindowDimensions from "../../Hooks/useWindowDimensions";
import { Skeleton } from "antd";
import { useAuthState } from "../Shared/AuthContext/AuthContext";
import { message } from "antd";
import { Button } from 'antd';

/**
 * Display all the items of an selected order
 * @param selected selected order name
 * @param changeFocus function for change the component
 * @returns {React.FC}
 */
const ItemList = () => {
  const { order_id, order_name }: any = useParams();
  const [items, setItems] = useState<item[]>([]);
  const [loading, setLoading] = useState(false);
  const subs = [
    "Click an item to see the media list",
    "Click a media to visualize",
  ];
  const [history, setHistory] = useState([]);
  const [title, setTitle] = useState("");
  const h = useHistory();
  const { width } = useWindowDimensions();
  const { user }: any = useAuthState();
  const [ITN, setITN] = useState("");

  useEffect(() => {
    getOrders().then((data: orders[]) => {
      if (data.filter((i) => i.id === order_id).length !== 0) {
        getItems();
      } else {
        message.info("QrCode is not valid!");
        h.push("/orders");
      }
    });
  }, []);

  const getOrders = async () => {
    try {
      let res = await GET_REQUEST<orders[]>(ORDERS_PATH, {
        users: user.id,
      });

      return await res;
    } catch (error) {
      return [];
    }
  };

  /**
   * Get all items from backend,
   * and set state
   */
  const getItems = async () => {
    setLoading(true);
    try {
      GET_REQUEST<item[]>(ITEMS_PATH, { orders: order_id })
        .then(setItems)
        .catch((error) => console.log(error))
        .finally(() => setLoading(false));
    } catch (error) {
      ErrorHandler(error.response);
    }
  };

  /**
   * change component between Order, Item and Media
   * @param focus to understand on which component selected
   */
  const changeFocus = (focus, title, itn) => {
    setHistory((history) => [...history, focus]);
    setTitle(title);
    setITN(itn);
  };

  /**
   * come back from Media to Item
   */
  const back = () =>
    setHistory((history) => {
      history.pop();
      return [...history];
    });

  const sortStyles = (): React.CSSProperties => {
    if (width < 450) {
      return {
      };
    }
    else {
      return {
        position: "absolute",
        right: "0",
        top: "50%",
        transform: "translateY(-50%)",
      };
    }
  }

  return (
    <Layout>
      {console.log(sortStyles())}
      <PageHeader
        title={
          <div>
            <ArrowLeftOutlined
              style={{ fontSize: "1rem", marginRight: "1rem" }}
              onClick={() => (history.length > 0 ? back() : h.push("/orders"))}
            />
            {history.length === 0 ? order_name : title}

            <div
              id="sort"
              style={sortStyles()}
            />
          </div>
        }
        subTitle={subs[history.length]}
      />

      {history.length === 0 ? (
        <Skeleton loading={loading} active>
          {items.length > 0 ? (
            <Row
              gutter={[24, 24]}
              className={"grid"}
              style={{ flexDirection: width < 700 ? "column" : "row" }}
            >
              {items.map((i: any) => (
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={8}
                  xl={8}
                  xxl={6}
                  key={i.sn}
                  onClick={changeFocus.bind(this, i.sn, i.sn, i.item_type_name)}
                  style={{ textAlign: "center" }}
                >
                  <Card
                    title={i.sn}
                    bordered={false}
                    className={"customer-order-detail-card"}
                  >
                    <Typography.Title level={5} style={{ color: "gray" }}>
                      {i.name}
                    </Typography.Title>
                    <Typography.Text style={{ color: "gray" }}>
                      {i.description}
                    </Typography.Text>
                  </Card>
                </Col>
              ))}
            </Row>
          ) : (
            <Empty
              description="No item found"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )}
        </Skeleton>
      ) : (
        <Media key="media" sn={title} itn={ITN} />
      )}
    </Layout>
  );
};

export default ItemList;
