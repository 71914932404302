// @ts-ignore
import React, { useState } from "react";
import { Modal, Button, Form, Input } from "antd";
import { MailOutlined, QrcodeOutlined } from "@ant-design/icons";
import { UserOutlined } from '@ant-design/icons';
import { API_REQUEST, EMAIL_PATH } from "./Shared/Requests";
import { POST } from './Shared/Requests';
import { isEmailValid } from "./Shared/CommonFunctions";
import { message } from 'antd';

const UseRequireCredentialsModal = () => {
  const [visible, setVisible] = useState(false);

  const toggleRequireCredentialsVisible = () =>
    setVisible((visible) => !visible);

  const onFinish = (data) => {
    try {
      if (!isEmailValid(data.email)) {
        message.error("Email is not valid");
        return;
      }

      API_REQUEST(EMAIL_PATH, POST, {
        template: "FAI_CREDENTIALS_REQUEST",
        to: "app@faicarvico.it",
        payload: data,
      })
        .then((data) => {
          console.log(data);
          message.success("Your request has been successfully submitted");
          toggleRequireCredentialsVisible();
        })
        .catch((e) => {
          console.error(e.response);
          message.error(e.response.data.error_message);
        });
    } catch (error) {
      console.error(error);
    }
  };

  return {
    RequireCredentialModal: () => (
      <Modal
        centered
        title="Require credentials for this email"
        visible={visible}
        onCancel={toggleRequireCredentialsVisible}
        zIndex={10000}
        footer={false}
      >
        <Form onFinish={onFinish}>
          <Form.Item
            name="email"
            rules={[{ required: true, message: "Email required." }]}
            style={{ flex: 99 }}
          >
            <Input
              size={"large"}
              type="email"
              placeholder="Email"
              prefix={<MailOutlined />}
            />
          </Form.Item>
          <Form.Item
            name="job"
            rules={[{ required: true, message: "Reference required." }]}
            style={{ flex: 99, marginBottom: "5px" }}
          >
            <Input
              size={"large"}
              type="text"
              placeholder="Ref"
              prefix={<QrcodeOutlined />}
            />
          </Form.Item>
          <span style={{ color: "gray"}}>
            Reference you can find under your QR code
          </span>

          <Form.Item style={{marginTop: "20px"}}>
            <Button size={"large"} htmlType="submit" type="primary">
              Require
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    ),
    toggleRequireCredentialsVisible,
  };
};

export default UseRequireCredentialsModal;
