import React, { useState } from "react";
import { Form, Input, Button, message, Modal } from "antd";
import { MailOutlined, LockOutlined, UserOutlined } from "@ant-design/icons";
// @ts-ignore
import logoUrl from "../Resources/Logo.png";
import UseRequireCredentialsModal from "./UseRequireCredentialsModal";
import { ErrorHandler } from "./Shared/ErrorHandler";
import { useHistory } from "react-router-dom";
import { loginUser, useAuthDispatch, useAuthState } from "./Shared/AuthContext";
import { useParams } from "react-router-dom";
import { API_REQUEST, EMAIL_PATH, POST } from "./Shared/Requests";

const Login = () => {
  const { RequireCredentialModal, toggleRequireCredentialsVisible } =
    UseRequireCredentialsModal();
  const history = useHistory();
  const dispatch = useAuthDispatch();
  const { loading, isAdmin, errorMessage }: any = useAuthState();
  const [showForgotModal, setShowForgotModal] = useState(false)

  const onFinish = async (data: any) => {
    const path = history.location.pathname.split("/");
    try {
      let response = await loginUser(dispatch, data);
      if (!response) throw response;

      path.length === 4
        ? history.push(
            `/orders/${path[path.length - 2]}/${path[path.length - 1]}`
          )
        : history.push(`/profile/initialLogin`);
      message.success("User logged in successful");
    } catch (error) {
      ErrorHandler(errorMessage);
    }
  };

  const forgotPassword = () => {
    setShowForgotModal(!showForgotModal);
  }

  const onFinishForgotPassword = async (data) => {
    try {
      API_REQUEST(EMAIL_PATH, POST, {
        template: "FAI_RESET_PASSWORD",
        to: data.mail,
      })
        .then((data) => {
          message.success("Password reset email sent");
          setShowForgotModal(false);
        })
        .catch((error) => message.error("Email not found"));
    } catch (error) {
      ErrorHandler(error.response);
    }
  }

  return (
    <>
      <RequireCredentialModal />

      <Modal
        centered
        title="Request reset password"
        visible={showForgotModal}
        footer={false}
        zIndex={10000}
        onCancel={() => setShowForgotModal(false)}
      >
        <Form onFinish={onFinishForgotPassword}>
          <Form.Item
            name="mail"
            rules={[{ required: true, message: "E-mail required." }]}
          >
            <Input
              placeholder="Insert your registered e-mail"
              size={"middle"}
              type="text"
              prefix={<UserOutlined style={{ marginRight: "5px" }} />}
            />
          </Form.Item>
          <Form.Item style={{width: "100%", margin: "0"}}>
            <Button htmlType="submit" type="primary" style={{float: "right"}}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <div className="login">
        <Form onFinish={onFinish}>
          <div className="login-main">
            <div className="login-main-top">
              <Form.Item className="login-logo">
                <img src={logoUrl} alt="logo" />
              </Form.Item>

              <Form.Item
                name="username"
                rules={[{ required: true, message: "Username required." }]}
              >
                <Input
                  placeholder="Username"
                  size={"large"}
                  type="text"
                  prefix={<UserOutlined style={{ marginRight: "5px" }} />}
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: "Password required." }]}
              >
                <Input.Password
                  placeholder="Password"
                  size={"large"}
                  prefix={<LockOutlined style={{ marginRight: "5px" }} />}
                />
              </Form.Item>
              <div style={{ width: "100%" }}>
                <Button
                  type="link"
                  style={{ padding: 0, float: "right" }}
                  onClick={forgotPassword}
                >
                  Forgot password?
                </Button>
              </div>
            </div>

            <div className="login-main-bottom">
              <Form.Item>
                <Button htmlType="submit" loading={loading}>
                  LOGIN
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  type="link"
                  style={{ color: "white", border: "white solid 1px" }}
                  onClick={toggleRequireCredentialsVisible}
                >
                  REQUIRE CREDENTIALS
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default Login;
